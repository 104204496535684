import React from "react";
import { Link } from "react-router-dom";
import {
  about,
  careers,
  contact,
  core_value,
  home,
  insight,
  mision,
  people,
  service,
  vision,
  visionmision,
} from "../utils/string";
import { SearchBar, Language } from "../components";
import { useState } from "react";

const Header = ({ services, main, pages }) => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");
  const [searchQuery, setSearchQuery] = useState(query || "");
  return (
    <div>
      <div className="top">
        <div className="container" style={{ paddingBottom: "10px" }}>
          <div className="wrap-top">
            <div
              className="flat-top flat-language"
              style={{ marginRight: "0px", paddingLeft: "60px" }}
            >
              <Language />
            </div>
          </div>
        </div>
      </div>
      <div className="flat-header-wrap" style={{ backgroundColor: "#fff" }}>
        <div className="header widget-header clearfix">
          <div className="container">
            <div className="header-wrap clearfix">
              <div className="row">
                <div className="col-lg-3">
                  <div id="logo" className="logo">
                    <a href="/" rel="home">
                      <img
                        className="pt-0 pb-3"
                        src={main.FULLPATH_LOGO_CLIENT}
                        style={{ width: "150px" }}
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-9" style={{ paddingLeft: "0px" }}>
                  <div className="wrap-widget-headerr clearfix">
                    {/* <div style={{padding: "37px 0px 30px"}}> */}
                    <aside className="widget widget-info"></aside>
                    <aside className="widget widget-info">
                      <div className="textwidget clearfix">
                        <ul className="flat-top social-links">
                          <li>
                            {/* <div className="info-icon">
                                    <i className="fa fa-phone" />
                                </div>
                                <div className="info-text">
                                <a className="text-decoration-none" target="_blank" rel="noreferrer" title="Call Us">
                                    <h6>{main.NO_TELP}</h6>
                                </a>
                                </div> */}
                          </li>
                          <li>
                            {/* <div className="info-icon">
                                    <i className="fa fa-envelope" />
                                </div>
                                <div className="info-text">
                                <a className="text-decoration-none" target="_blank" rel="noreferrer" title="Mail Us">
                                    <h6>{main.EMAIL}</h6>
                                </a>
                                </div> */}
                          </li>
                        </ul>
                      </div>
                    </aside>
                  </div>
                  <div
                    id="header"
                    className="header header-classic header-style1"
                  >
                    <div
                      className="col-md-12 pr-0"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div className="flat-wrap-header">
                        <div className="nav-wrap clearfix">
                          <nav id="mainnav" className="mainnav">
                            <ul className="menu">
                              <li className="active">
                                <a href="/">{main.HOME}</a>
                              </li>
                              <li>
                                <Link to="/service"> {main.SERVICES}</Link>
                                <ul className="submenu">
                                  {services &&
                                    services.map((d) => (
                                      <li key={d.ID}>
                                        <Link to={"/service/" + d.SLUG}>
                                          {d.TITLE}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </li>
                              <li>
                                <Link to="/about"> {main.ABOUT}</Link>
                                <ul className="submenu">
                                  <li>
                                    <Link to="/visimisi">
                                      {" "}
                                      {main.VISI_MISI}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/value"> {main.CORE_VALUE}</Link>
                                  </li>
                                  <li>
                                    <Link to="/person"> {main.PEOPLE}</Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="/tax/page/1"> {main.NEWS}</Link>
                              </li>
                              <li>
                                <Link to="/career">{main.CAREERS}</Link>
                              </li>

                              <li>
                                <Link to="/contact"> {main.CONTACTS}</Link>
                              </li>

                              <li id="s" className="show-search">
                                <p>
                                  <a href="#search" className="flat-search">
                                    <i className="fa fa-search" />
                                  </a>
                                </p>
                              </li>
                            </ul>
                            {/* /.menu */}
                          </nav>
                          {/* /.mainnav */}
                          <div className="top-search">
                            <SearchBar
                              searchQuery={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </div>
                          <div className="btn-menu">
                            <span />
                          </div>
                          {/* //mobile menu button */}
                        </div>
                        {/* /.nav-wrap */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.header-wrap */}
          </div>
        </div>
        {/* /.header */}
      </div>
    </div>
  );
};

export default Header;
