import React, { Component } from "react";
import {
  PageHeader,
  PageBody,
  Leader,
  Expert,
  ProjectHome,
  MenuAbout,
} from "../components";
import {
  API_MAIN,
  API_PERSON_LEADER,
  API_PERSON_EXPERT,
  API_STEP_LANDING,
} from "../utils/api";
import axios from "axios";
import {
  about,
  core,
  about_desc_detail,
  who_we_are,
  people,
  vision,
} from "../utils/string";
import bg_about_us from "../assets/images/pageHeader/about-us-image-rev.jpg";

export class About_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: {
        title: about[window.lang],
        subtitle: "",
        breadcrumb: about[window.lang],
        back: "about",
        bg_image: bg_about_us,
      },
      misi: [],
      visi: [],
      value: [],
      about: [],
      expert: [],
      leader: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const misi = res.data.main.MISI;
        const visi = res.data.main.VISI;
        const value = res.data.main.VALUE;
        const about = res.data.main.ABOUT_US;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ misi });
        this.setState({ visi });
        this.setState({ value });
        this.setState({ about });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });

    this.getPersonExpert();
    this.getPersonLeader();
    this.getStep();
  }

  getPersonExpert() {
    const headers = {};
    axios
      .get(API_PERSON_EXPERT, { headers: headers })
      .then((res) => {
        const expert = res.data.person;
        this.setState({ expert });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getStep() {
    const headers = {};
    axios
      .get(API_STEP_LANDING, { headers: headers })
      .then((res) => {
        const step = res.data.strategies;
        this.setState({ step });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getPersonLeader() {
    const headers = {};
    axios
      .get(API_PERSON_LEADER, { headers: headers })
      .then((res) => {
        const leader = res.data.person;
        this.setState({ leader });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { misi, visi, value, expert, leader, main, about } = this.state;
    const header = {
      title: main.about,
      subtitle: "",
      breadcrumb: main.about,
      back: main.about,
      home: main.home,
      bg_image: bg_about_us,
    };
    return (
      <main>
        <div role="main" className="main" id="About">
          <PageHeader param={header} />
          <div className="container pl-3">
            <div
              className="row"
              style={{ marginTop: "30px", marginBottom: "80px" }}
            >
              <div className="col">
                <h2>{main.who_we_are}</h2>
                <p align="justify">
                  <PageBody param={about} />
                </p>
              </div>
            </div>
            {/* <div className="row" style={{marginTop:'30px'}}>
                            <div className="col">
                                <h2>{mision[window.lang]}</h2>
                                <p align="justify">
                                    <PageBody param={misi} />
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col">
                                <h2>{core[window.lang]}</h2>
                                <p align="justify">
                                    <PageBody param={value} />
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col">
                                <h2>{people[window.lang]}</h2>
                                <p align="justify" style={{marginLeft: '17px'}}>
                                    {desc_people[window.lang]}
                                </p>
                            </div>
                        </div> */}
          </div>
          <ProjectHome />
          <MenuAbout />
          <div className="blank"></div>
          {/* <div style={{marginTop:'30px'}}>
                        <Leader data={leader} />
                    </div>
                    <Expert data={expert} /> */}
        </div>
      </main>
    );
  }
}

export default About_page;
