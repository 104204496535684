import React, { Component } from "react";
import { PageHeader, PageBody, ProjectHome, MenuAbout } from "../components";
import { API_MAIN } from "../utils/api";
import { core_value } from "../utils/string";
import axios from "axios";
import bg_core_value from "../assets/images/pageHeader/img-corevalue-header.jpg";

export class Value extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const content = res.data.main.VALUE;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ content });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { content, main } = this.state;
    const header = {
      title: main.CORE_VALUE,
      subtitle: main.CORE_VALUE,
      back: main.CORE_VALUE,
      breadcrumb: main.CORE_VALUE,
      bg_image: bg_core_value,
      home: main.home,
    };
    return (
      <main>
        <div
          role="main"
          className="main"
          style={{ paddingBottom: "30px" }}
          id="CoreValue"
        >
          <PageHeader param={header} />
          <div className="container py-2" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col">
                <h2>{header.breadcrumb}</h2>
              </div>
            </div>
          </div>
          <div className="container py-2" style={{ marginBottom: "60px" }}>
            <PageBody param={content} title={header} />
          </div>
          <ProjectHome />
          <hr className="solid tall mt-5" />
          <MenuAbout />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Value;
