import React from "react";
import { Link } from "react-router-dom";
import { getYear, getDay, getMonth } from "../utils/util";
import { RecentPost } from "../components";
import { categories, read_more, recent_post } from "../utils/string";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LineIcon,
  TelegramIcon,
  LinkedinIcon,
} from "react-share";

const DetailTaxBody = ({ param, title, category, news, main }) => {
  const download = [];
  if (param.FEATURE_DOC) {
    // download.push(<a href={"http://localhost/pro-visioner/admin/docs/index/"+param.FEATURE_DOC} target="_blank">DOWNLOAD</a>)
    download.push(
      <Link
        to={"/admin/docs/index/" + param.FEATURE_DOC}
        className="flat-button subscribe-submit ml-5 mr-5"
        style={{ background: "#0c4491" }}
        target="_blank"
      >
        DOWNLOAD DOC
      </Link>
    );
  }
  return (
    <section className="flat-row services-details">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-3">
              <div className="sidebar">
                <div className="widget widget-help">
                  <div className="featured-widget">
                    <img src={param.FULL_PATH_IMAGE} alt="images" />
                  </div>
                  <p>
                    Posted By: Admin pro-visioner
                    <br/>
                    Date: {getDay(param.DATE_NEWS)} {getMonth(param.DATE_NEWS)} {getYear(param.DATE_NEWS)}
                    <br/>
                    Tags: {param.TAG}
                  </p>
                </div>
              </div>
            </div> */}
          <div className="col-lg-9">
            <div className="item-wrap">
              <div className="item item-details">
                <div className="featured-widget">
                  <img
                    src={param.FULL_PATH_IMAGE}
                    alt="images"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
                <div className="content-item pl-5 pr-5 pt-3">
                  <h5 className="title-post">
                    <h6>{param.TITLE}</h6>
                  </h5>
                  <p>
                    {getDay(param.DATE_NEWS)} {getMonth(param.DATE_NEWS)}{" "}
                    {getYear(param.DATE_NEWS)}
                  </p>

                  <h2 className="title-item pt-2">{title.detail_title}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: param.DESC_NEWS }}
                  ></div>
                </div>
                {download}
                <div className="pr-5 pl-5 pt-3 pb-5">
                  Share: &nbsp;
                  <TwitterShareButton url={window.location.href}>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  &nbsp;
                  <FacebookShareButton url={window.location.href}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  &nbsp;
                  <LinkedinShareButton url={window.location.href}>
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                  &nbsp;
                  <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                  &nbsp;
                  <LineShareButton url={window.location.href}>
                    <LineIcon size={32} round={true} />
                  </LineShareButton>
                  &nbsp;
                  <TelegramShareButton url={window.location.href}>
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </div>
              </div>
            </div>
          </div>
          <RecentPost />
          <div className="col-lg-9 pt-3">
            <h2>{main.other_news}</h2>
            <hr className="solid tall" />
            {news &&
              news.map((d) => (
                <div className="entry-border clearfix pl-5">
                  <p>
                    {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                    {getYear(d.DATE_NEWS)} | Admin
                  </p>
                  <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                    <h6>{d.TITLE}</h6>
                  </a>
                  {/* <Link to={"/tax/" + d.SLUG + "#detailTax"}>
                    <h6>{d.TITLE}</h6>
                  </Link> */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailTaxBody;
