import React, { Component } from "react";
import { PageHeader, ServicePageBody, ProjectHome } from "../components";
import { getYear, getDay, getMonth } from "../utils/util";
import axios from "axios";
import {
  API_SUBMIT_LAMARAN,
  API_MAIN,
  API_UPDATE_MONEV,
  API_GET_MONEV,
} from "../utils/api";
import { careers as car, sub_careers } from "../utils/string";
import bg_our_service from "../assets/images/pageHeader/img-career-header.jpg";

export class Kuisioner extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      no: "",
      slug: "",
      main: [],
      detail: [],
      service: [],
      client: [],
      clientContact: [],
      clientaddress: [],
      data: [],
      selectedFile: null,
      message: "",
    };
  }

  componentDidMount() {
    this.getMain();
    this.getData(this.props.match.params.no, this.props.match.params.slug);
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getData(no, slug) {
    const headers = {};
    axios
      .get(API_GET_MONEV + "/" + no + "/" + slug, { headers: headers })
      .then((res) => {
        const detail = JSON.parse(res.data.data.RAW_DATA);
        const service = detail.Service;
        const client = detail.Client;
        const clientContact = client.ClientContact;
        const data = res.data.data;
        console.log(data);

        this.setState({ detail });
        this.setState({ service });
        this.setState({ client });
        this.setState({ clientContact });
        this.setState({ data });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const element = document.getElementsByClassName(name);
    element[0].textContent =
      value.replace("0", "") == "" ? "0" : value.replace("0", "");
  };

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = new FormData();

    data.append("no", formData.get("no"));
    data.append("client", formData.get("client"));
    data.append(
      "service_feedback_point",
      formData.get("service_feedback_point")
    );
    data.append(
      "service_feedback_quest2",
      formData.get("service_feedback_quest2")
    );
    data.append(
      "service_feedback_quest3",
      formData.get("service_feedback_quest3")
    );
    data.append(
      "service_feedback_quest4",
      formData.get("service_feedback_quest4")
    );
    data.append("question_5a", formData.get("question_5a"));
    data.append("question_5b", formData.get("question_5b"));
    data.append("question_5c", formData.get("question_5c"));
    data.append("question_5d", formData.get("question_5d"));
    // data.append("question_5e", formData.get("question_5e"));

    axios
      .post(API_UPDATE_MONEV, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.data}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.data}
            </div>
          );
          this.setState({ message });
          setInterval(function () {
            window.location.href = "/thank";
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  render() {
    const {
      service,
      client,
      clientaddress,
      data,
      detail,
      main,
      message,
    } = this.state;
    const param = {
      title: main.Questionnaire,
      subtitle: sub_careers[window.lang],
      breadcrumb: main.Questionnaire,
      back: "career",
      bg_image: bg_our_service,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section
            className="flat-row page-careers"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div
                className="careers-open pl-5 pr-5"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply  pl-5 pr-5 pt-5 pb-3">
                  <h3 style={{ marginBottom: "50px" }}>{main.Questionnaire}</h3>

                  <div className="row">
                    <article className="blog-post col-lg-4">
                      <h5>{main.JobSetup}</h5>
                      <p class="mb-0">
                        <span>{data.JOBSETUP_NO}</span>
                      </p>
                      <p class="mb-0">
                        <span>{data.SERVICE_NAME}</span>
                      </p>
                      <p class="mb-0">
                        <span>{detail.JobSetupFiscalYears}</span>
                      </p>
                      <p class="mb-0">
                        <span>
                          {getDay(detail.JobSetupPeriodeStart)}{" "}
                          {getMonth(detail.JobSetupPeriodeStart)}{" "}
                          {getYear(detail.JobSetupPeriodeStart)}{" "}
                          &nbsp;-&nbsp;&nbsp;
                          {getDay(detail.JobSetupPeriodeFinish)}{" "}
                          {getMonth(detail.JobSetupPeriodeFinish)}{" "}
                          {getYear(detail.JobSetupPeriodeFinish)}
                        </span>
                      </p>
                    </article>
                    <article className="blog-post col-lg-4">
                      <h5>{main.Client}</h5>
                      <p class="mb-0">
                        <span>
                          {this.props.match.params.slug.replace(/-/g, " ").replace("%2C",",")}
                        </span>
                      </p>
                      {/* <p class="mb-0">
                        <span>{client.ClientWebsite}</span>
                      </p>
                      <p class="mb-0">
                        <span>{client.ClientNpwpNo}</span>
                      </p>
                      <p class="mb-0">
                        <span>{client.ClientDesc}</span>
                      </p> */}
                    </article>
                    {/* <article className="blog-post col-lg-3">
                      <h5>{main.ClientContact}</h5>
                      {clientContact &&
                        clientContact.map((d, i) => (
                          <div>
                            <p className="mb-0">
                              <span>{d.ClientContactFirstName}</span>
                            </p>
                            <p className="mb-0">
                              <span>
                                <i className="fa fa-envelope" />
                                &nbsp;&nbsp;
                                {d.ClientContactEmail}
                              </span>
                            </p>
                            <p className="mb-0">
                              <span>
                                <i className="fa fa-phone" />
                                &nbsp;&nbsp;
                                {d.ClientContactMobilePhone}
                              </span>
                            </p>
                          </div>
                        ))}
                    </article> */}
                    <article className="blog-post col-lg-4 mb-1">
                      <h5>{main.ManagerAssist}</h5>
                      <div>
                        <p className="mb-0">
                          <span>{data.MANAGE_ASSIST}</span>
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div
                className="careers-open  pl-5 pr-5 pt-3 pb-3"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply pb-3">
                  {/* <h1>{main.upload_cv}</h1> */}
                  <form
                    onSubmit={this.handleSubmit}
                    className="custom-contact-form-style-1 contactform style4  clearfix"
                    enctype="multipart/form-data"
                  >
                    <div className="form-row">
                      <div className="form-group col">
                        <div className="custom-input-box">
                          {/* <i className="icon-user icons text-color-primary" /> */}
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={data.JOBSETUP_NO}
                            className="form-control"
                            name="no"
                            placeholder={main.name}
                            required
                          />
                          <input
                            type="hidden"
                            data-msg-required="Please enter your name."
                            value={this.props.match.params.slug.replace(
                              /-/g,
                              " "
                            ).toUpperCase()}
                            className="form-control"
                            name="client"
                            placeholder={this.props.match.params.slug.replace(
                              /-/g,
                              " "
                            ).toUpperCase()}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <h5> {main.title_kuisioner}</h5>
                        <span>{main.desc_kuisioner}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <h4
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {main.service_feeback}
                        </h4>
                        <p class="mb-0">
                          <div className="form-row mb-5">
                            <div className="form-group col">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: main.question_1,
                                }}
                              ></span>
                              <div
                                className="custom-input-box"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  // value={5}
                                  step={10}
                                  className="form-control"
                                  name="service_feedback_point"
                                  onChange={this.handleChange}
                                  required
                                />
                                <div className="indicator service_feedback_point">
                                  5
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <span
                                  className="mt-5"
                                  dangerouslySetInnerHTML={{
                                    __html: main.question_2,
                                  }}
                                ></span>
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control mt-3"
                                  name="service_feedback_quest2"
                                  placeholder="Note"
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <span
                                  className="mt-5"
                                  dangerouslySetInnerHTML={{
                                    __html: main.question_3,
                                  }}
                                ></span>
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control mt-3"
                                  name="service_feedback_quest3"
                                  placeholder="Note"
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col">
                              <div className="custom-input-box">
                                <span
                                  className="mt-5"
                                  dangerouslySetInnerHTML={{
                                    __html: main.question_4,
                                  }}
                                ></span>
                                <i className="icon-bubble icons text-color-primary" />
                                <textarea
                                  maxLength={5000}
                                  rows={10}
                                  className="form-control mt-3"
                                  name="service_feedback_quest4"
                                  placeholder="Note"
                                  defaultValue={""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col">
                              <span
                                className="mt-5"
                                dangerouslySetInnerHTML={{
                                  __html: main.question_5,
                                }}
                              ></span>
                            </div>
                          </div>
                          <div className="form-row">
                              <div  className="form-group col">
                                <table class="table table-responsive">
                                  <thead>
                                    <tr>
                                      <th width="50%"></th>
                                      <th>
                                        <h5>{main.poor}</h5>
                                      </th>
                                      <th>
                                        <h5>{main.fair}</h5>
                                      </th>
                                      <th>
                                        <h5>{main.good}</h5>
                                      </th>
                                      <th>
                                        <h5>{main.excellent}</h5>
                                      </th>
                                      <th>
                                        <h5>{main.exceptional}</h5>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span>{main.question_5a}</span>
                                      </td>
                                      {/* <div className="custom-input-box"> */}
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5a"
                                          value="10"
                                          required
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5a"
                                          value="20"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5a"
                                          value="30"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5a"
                                          value="40"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5a"
                                          value="50"
                                          required
                                        />
                                      </td>
                                      {/* </div> */}
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{main.question_5b}</span>
                                      </td>
                                      {/* <div className="custom-input-box"> */}
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5b"
                                          value="10"
                                          required
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5b"
                                          value="20"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5b"
                                          value="30"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5b"
                                          value="40"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5b"
                                          value="50"
                                          required
                                        />
                                      </td>
                                      {/* </div> */}
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{main.question_5c}</span>
                                      </td>
                                      {/* <div className="custom-input-box"> */}
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5c"
                                          value="10"
                                          required
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5c"
                                          value="20"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5c"
                                          value="30"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5c"
                                          value="40"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5c"
                                          value="50"
                                          required
                                        />
                                      </td>
                                      {/* </div> */}
                                    </tr>
                                    <tr>
                                      <td>
                                        <span>{main.question_5d}</span>
                                      </td>
                                      {/* <div className="custom-input-box"> */}
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5d"
                                          value="10"
                                          required
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5d"
                                          value="20"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5d"
                                          value="30"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5d"
                                          value="40"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5d"
                                          value="50"
                                          required
                                        />
                                      </td>
                                      {/* </div> */}
                                    </tr>
                                    {/* <tr>
                                      <td>
                                        <span>{main.question_5e}</span>
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5e"
                                          value="10"
                                          required
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5e"
                                          value="20"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5e"
                                          value="30"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5e"
                                          value="40"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <input
                                          class="radioButton"
                                          type="radio"
                                          name="question_5e"
                                          value="50"
                                          required
                                        />
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>  
                              </div>
                          </div>                          
                        </p>
                      </div>
                    </div>
                    <div className="message">{message}</div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <span className="flat-input">
                          <button
                            name="submit"
                            type="submit"
                            className="flat-button-cv"
                            id="submit"
                            title={main.send_invite}
                          >
                            {main.send_invite}
                          </button>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Kuisioner;
