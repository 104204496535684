import React, { Component } from "react";
import { PageHeader, ServicePageBody, ProjectHome } from "../components";
import axios from "axios";
import { API_SUBMIT_LAMARAN, API_MAIN } from "../utils/api";
import { careers as car, sub_careers } from "../utils/string";
import bg_our_service from "../assets/images/pageHeader/img-career-header.jpg";

export class Upload_cv extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
    this.state = {
      slug: "",
      main: [],
      selectedFile: null,
      message: "",
    };
  }

  componentDidMount() {
    this.getMain();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = new FormData();

    data.append("email", formData.get("email"));
    data.append("name", formData.get("name"));
    data.append("position", formData.get("position"));
    data.append("message", formData.get("message"));
    data.append("userfile", this.fileInput.current.files[0]);

    axios
      .post(API_SUBMIT_LAMARAN, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        // var data = JSON.parse(res)
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.msg}
            </div>
          );
          this.setState({ message });
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { main, message } = this.state;
    const param = {
      title: car[window.lang],
      subtitle: sub_careers[window.lang],
      breadcrumb: car[window.lang],
      back: "career",
      bg_image: bg_our_service,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section
            className="flat-row page-careers"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div
                className="careers-open pl-5 pr-5"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="info-apply  pl-5 pr-5 pt-3 pb-3">
                  <h1 style={{ marginBottom: "50px" }}>Upload Your CV</h1>
                  <div className="message">{message}</div>
                  <form
                    onSubmit={this.handleSubmit}
                    className="contactform style4  clearfix"
                    enctype="multipart/form-data"
                  >
                    <span className="flat-input">
                      <input
                        name="name"
                        type="text"
                        placeholder="Name*"
                        required="required"
                      />
                    </span>
                    <span className="flat-input">
                      <input
                        name="email"
                        type="email"
                        placeholder="Email*"
                        required="required"
                      />
                    </span>
                    <span className="flat-input">
                      <input
                        name="position"
                        type="text"
                        placeholder="Position"
                        required="required"
                      />
                    </span>
                    <span className="flat-input">
                      <textarea
                        name="message"
                        placeholder="Messages"
                        required="required"
                        defaultValue={""}
                      />
                    </span>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <span className="flat-input">
                            <input
                              type="file"
                              name="userfile"
                              id="userfile"
                              ref={this.fileInput}
                            />
                            (File types: PDF Max-size: 2 MB)
                          </span>
                          <span></span>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <span className="flat-input">
                            <button
                              name="submit"
                              type="submit"
                              className="flat-button-cv"
                              id="submit"
                              title="Submit now"
                            >
                              send
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Upload_cv;
