import React from "react";
import { FaWindows } from "react-icons/fa";
import bg_contact from "../assets/images/our-team-home2.webp";
import {
  teamwork,
  our_expert,
  find_out_more,
  our_expert_desc,
} from "../utils/string";

const Teamwork = ({ main }) => {
  return (
    <div className="About">
      <div className="container-fluid">
        <div className="row mb-20">
          <article className="blog-post col">
            <div className="row justify-content-end">
              <div className="padi">
                <div className="col-lg-6">
                  <div className="title-section style3 left">
                    <h6 className="title">{main.teamwork}</h6>
                  </div>
                  <h2>{main.our_expert}</h2>
                  <p>
                    {main.our_expert_desc}
                    {/* <span dangerouslySetInnerHTML={ { __html: d.DESC_CAREER?.replace(/<[^>]+>/g, '') } }></span>... */}
                    {/* <div dangerouslySetInnerHTML={ { __html: dasdsadsadsa} }></div> */}
                  </p>
                  <h6 className="mt-3">
                    <a href={"/person"} title="{find_out_more[window.lang]}">
                      {main.find_out_more}
                    </a>
                  </h6>
                </div>
              </div>
              <div className="col-sm-8 col-lg-6 pb-5 pl-0 pr-0">
                <div className="blog-post-image-wrapper">
                  <a href={"/person"} title="Read More">
                    <img src={bg_contact} alt="" width="100%" className="img" />
                  </a>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Teamwork;
