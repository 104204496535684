import React from "react";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import about_us from "../assets/images/about-us-image.jpg";
import bg_our_team from "../assets/images/our-team.jpg";
import bg_core_value from "../assets/images/core-value.jpg";
import visi_misi from "../assets/images/visi-misi.jpg";
import { API_MAIN } from "../utils/api";

import {
  about,
  core,
  our_team,
  core_value,
  visionmision,
  vision,
} from "../utils/string";

import { Link } from "react-router-dom";

export class MenuAbout extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      { width: 850, itemsToShow: 4 },
      { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
      { width: 1450, itemsToShow: 5 },
      { width: 1750, itemsToShow: 6 },
    ];
    this.state = {
      leader: [],
      main: [],
    };
  }
  componentDidMount() {
    this.getMain();
  }
  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  render() {
    const { leader, main } = this.state;
    var menu = "";
    if (main.LANG == "en") {
      menu = "";
    } else {
      menu = "menu";
    }
    return (
      <section className="flat-row v6">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col"></div>
              <div className="navigation-panah col-right">
                <span onClick={() => this.carousel.slidePrev()}>
                  <FaAngleLeft size={40} color="#f57f22" />
                </span>
                <span onClick={() => this.carousel.slideNext()}>
                  <FaAngleRight size={40} color="#f57f22" />
                </span>
              </div>
            </div>
            <div className="blog-shortcode post-grid">
              <Carousel
                ref={(ref) => (this.carousel = ref)}
                showArrows={false}
                itemsToShow={3}
                breakPoints={this.breakPoints}
                onResize={(currentBreakPoint) => console.log(currentBreakPoint)}
              >
                <article
                  className="entry border-shadow clearfix"
                  style={{ padding: "10px" }}
                >
                  <div className="entry-border clearfix">
                    <div
                      className="content-posts"
                      style={{
                        background: `url(${about_us}) no-repeat center center`,
                        backgroundSize: "cover",
                        width: "250px",
                        height: "300px",
                      }}
                    >
                      <div className="MenuLink">
                        <center>
                          <h3>
                            <a href={"/about#About"}>{main.about}</a>
                            {/* <Link to="/about#detailPerson">{main.about}</Link> */}
                          </h3>
                        </center>
                      </div>
                    </div>
                  </div>
                </article>

                <article
                  className="entry border-shadow clearfix"
                  style={{ padding: "10px" }}
                >
                  <div className="entry-border clearfix">
                    <div
                      className="content-posts"
                      style={{
                        background: `url(${bg_our_team}) no-repeat center center`,
                        backgroundSize: "cover",
                        width: "250px",
                        height: "300px",
                      }}
                    >
                      <div className="MenuLink">
                        <center>
                          <h3>
                            <a href={"/person/#Person"}>{main.our_team}</a>
                            {/* <Link to="/person">{main.our_team}</Link> */}
                          </h3>
                        </center>
                      </div>
                    </div>
                  </div>
                </article>

                <article
                  className="entry border-shadow clearfix"
                  style={{ padding: "10px" }}
                >
                  <div className="entry-border clearfix">
                    <div
                      className="content-posts"
                      style={{
                        background: `url(${bg_core_value}) no-repeat center center`,
                        backgroundSize: "cover",
                        width: "250px",
                        height: "300px",
                      }}
                    >
                      <div className="MenuLink">
                        <center>
                          <h3>
                            <a href={"/value/#CoreValue"}>{main.CORE_VALUE}</a>
                            {/* <Link to="/value">{main.CORE_VALUE}</Link> */}
                          </h3>
                        </center>
                      </div>
                    </div>
                  </div>
                </article>

                <article
                  className="entry border-shadow clearfix"
                  style={{ padding: "10px" }}
                >
                  <div className="entry-border clearfix">
                    <div
                      className="content-posts"
                      style={{
                        background: `url(${visi_misi}) no-repeat center center`,
                        backgroundSize: "cover",
                        width: "250px",
                        height: "300px",
                      }}
                    >
                      <div className="MenuLink">
                        <center>
                          <h3>
                            <a href={"/visimisi/#VisiMisi"}>{main.VISI_MISI}</a>
                            {/* <Link to="/visimisi">{main.VISI_MISI}</Link> */}
                          </h3>
                        </center>
                      </div>
                    </div>
                  </div>
                </article>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MenuAbout;
