import React from "react";
import Carousel from "react-elastic-carousel";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import axios from "axios";
import { API_PERSON_LEADER, API_MAIN } from "../utils/api";
import { our_expert, our_expert_desc, leaders } from "../utils/string";
import { Link } from "react-router-dom";

export class Leader extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      { width: 850, itemsToShow: 4 },
      { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
      { width: 1450, itemsToShow: 5 },
      { width: 1750, itemsToShow: 6 },
    ];
    this.state = {
      leader: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const misi = res.data.main.MISI;
        const visi = res.data.main.VISI;
        const value = res.data.main.VALUE;
        const about = res.data.main.ABOUT_US;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ misi });
        this.setState({ visi });
        this.setState({ value });
        this.setState({ about });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
    this.getPersonLeader();
  }

  getPersonLeader() {
    const headers = {};
    axios
      .get(API_PERSON_LEADER, { headers: headers })
      .then((res) => {
        const leader = res.data.person;
        this.setState({ leader });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { leader, main } = this.state;
    return (
      <section className="custom-section-padding mt-5">
        <div className="container">
          <div
            className="row"
            style={{ marginTop: "30px", marginBottom: "80px" }}
          >
            <div className="col">
              <h2>{main.our_expert}</h2>
              <p align="justify">{main.our_expert_desc}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>{main.leaders}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                className="row team-list sort-destination"
                data-sort-id="team"
              >
                {leader &&
                  leader.map((d) => (
                    // <div key={d.ID} className="col-sm-12">
                    //     <div className="flat-team">
                    //     <div className="avatar">
                    //         <img src={d.FULL_PATH_IMAGE} alt="imagea" />
                    //     </div>
                    //     <div className="content text-center">
                    //         <Link to={"/person/"+d.SLUG}><h5 className="name">{d.NAME}</h5></Link>
                    //         <p className="position">{d.POSITION}</p>
                    //         {/* <ul className="social-links style2 team">
                    //         <li><a href="/#"><i className="fa fa-facebook" /></a></li>
                    //         <li><a href="/#"><i className="fa fa-twitter" /></a></li>
                    //         <li><a href="/#"><i className="fa fa-vimeo" /></a></li>
                    //         <li><a href="/#"><i className="fa fa-instagram" /></a></li>
                    //         <li><a href="/#"><i className="fa fa-skype" /></a></li>
                    //         </ul> */}
                    //     </div>
                    //     </div>
                    // </div>
                    <article className="blog-post col-lg-6" key={d.ID}>
                      <div className="row">
                        <div className="col-sm-8 col-lg-5">
                          <div className="blog-post-image-wrapper">
                            <img
                              src={d.FULL_PATH_IMAGE}
                              alt="asdas"
                              className="img-fluid mb-4"
                              style={{
                                padding: "10px 10px",
                                backgroundColor: "#fff",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-7">
                          <h5 style={{ color: "#363232" }}>- {d.POSITION} -</h5>
                          <h2 className="title-item-person">{d.NAME}</h2>
                          <Link to={"/person/" + d.SLUG}>
                            <h6>
                              <a>DETAILS +</a>
                            </h6>
                          </Link>
                        </div>
                      </div>
                    </article>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Leader;
