import React, { Component } from "react";
import { PageHeader, PageBody, ProjectHome, MenuAbout } from "../components";
import { API_MAIN } from "../utils/api";
import axios from "axios";
import bg_visi from "../assets/images/visi-misi.jpg";

export class Visi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: {
        title: "Vision & Mision",
        misi: "Mision",
        visi: "Vision",
        bg_image: bg_visi,
      },
      content: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const content = res.data.main;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ content });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { header, content, main } = this.state;
    return (
      <main>
        <div role="main" className="main" style={{ paddingBottom: "30px" }}>
          <PageHeader param={header} />
          <div className="container py-2" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col">
                <h2>{header.visi}</h2>
              </div>
            </div>
          </div>
          <PageBody param={content.VISI} title={header} />
          <div className="container py-2" style={{ marginTop: "30px" }}></div>
          <ProjectHome />
          <hr className="solid tall mt-5" />
          <MenuAbout />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Visi;
