// export const API_URL = "http://localhost/provisio-id.com/admin/";
export const API_URL = "https://provisio-id.com/admin/";
export const API_MAIN = API_URL + "main/apiGetMain/";
export const API_BANNERS = API_URL + "sliders/apiGetSliders/";
export const API_FAQ_LANDING = API_URL + "faqs/apiGetFaqsForLanding/";
export const API_STEP_LANDING =
  API_URL + "strategies/apiGetStrategiesForLanding";
export const API_CLIENT_LANDING = API_URL + "clients/apiGetClientsForLanding/";

export const API_SERVICES = API_URL + "services/apiGetServicesForLanding/";
export const API_DETAIL_SERVICE = API_URL + "services/apiGetServicesBySlug/";
export const API_DETAIL_SERVICE_BYID = API_URL + "services/apiGetServicesById/";

export const API_CASE_TAGS = API_URL + "projects/apiGetProjectsTags/";
export const API_CASES = API_URL + "projects/apiGetProjects/";
export const API_DETAIL_CASE = API_URL + "projects/apiGetProjectsBySlug/";

export const API_NEWS = API_URL + "news/apiGetNews/";
export const API_NEWS_LANDING = API_URL + "news/apiGetNewsForLanding/";
export const API_DETAIL_NEWS = API_URL + "news/apiGetNewsBySlug/";
export const API_DETAIL_NEWS_BYID = API_URL + "news/apiGetNewsById/";
export const API_NEWS_CATEGORY = API_URL + "categorys/apiGetCategorys";

export const API_PERSON = API_URL + "person/apiGetPerson/";
export const API_PERSON_EXPERT = API_URL + "person/apiGetPersonExpert/";
export const API_PERSON_LEADER = API_URL + "person/apiGetPersonLeader/";
export const API_DETAIL_PERSON = API_URL + "person/apiGetPersonBySlug/";
export const API_DETAIL_PERSON_BYID = API_URL + "person/apiGetPersonById/";
export const API_OTHER_PERSON = API_URL + "person/apiGetPersonNotBySlug/";

export const API_PAGES = API_URL + "pages/apiGetPages/";
export const API_DETAIL_PAGE = API_URL + "pages/apiGetPagesBySlug/";

export const API_CAREER = API_URL + "careers/apiGetCareers/";
export const API_DETAIL_CAREER = API_URL + "careers/apiGetCareersBySlug/";
export const API_DETAIL_CAREER_BYID = API_URL + "careers/apiGetCareersById/";
export const API_SUBMIT_LAMARAN = API_URL + "careers/apiSubmitLamaran";

export const API_POST_MESSAGE = API_URL + "subscribers/apiPostSendMessage";

export const API_SET_LANGUAGE = API_URL + "main/apiPostSettingLang";

export const API_TAX_LEGAL = API_URL + "news/apiGetNewsKhususTaxAndRegulation";

export const API_SUBSCRIBE = API_URL + "subscribers/apiPostSubscribe";

export const API_DETAIL_EVENTS = API_URL + "events/apiGetEventsBySlug/";
export const API_DETAIL_EVENTS_BYID = API_URL + "events/apiGetEventsById/";
export const API_POST_KEHADIRAN = API_URL + "events/apiPostStatusKehadiran";

export const API_GET_MONEV = API_URL + "api/Monev";
export const API_UPDATE_MONEV = API_URL + "api/Monev";
