import React from "react";
import { Link } from "react-router-dom";
import { contact, how_we_can, desc_how_we } from "../utils/string";
import img_service from "../assets/images/services/how can we help.jpg";
import img_details from "../assets/images/services/taxservice-header.jpg";
import bg_contact_us from "../assets/images/pageHeader/img-contactus-header.jpg";

const ServicePageBody = ({ param, title, main }) => {
  return (
    <section className="flat-row services-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="sidebar">
              <div className="widget widget-help">
                <div className="featured-widget">
                  <img
                    className="imgg"
                    src={img_service}
                    alt="images"
                    width="100%"
                  />
                </div>
                <h3>{main.how_we_can}</h3>
                <p>{main.desc_how_we}</p>
                <Link to="/contact">
                  <a href="/#" className="flat-button style2">
                    {main.contact}
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="item-wrap">
              <div className="item item-details">
                <div className="featured-item">
                  <img src={img_details} alt="imagex" width="100%" />
                </div>
                <div className="content-item">
                  <h2
                    className="title-item"
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "27px",
                      marginBottom: "20px",
                    }}
                  >
                    {title.title}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: param.DESC_SERVICE }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicePageBody;
