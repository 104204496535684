import { BsJustify } from "react-icons/bs";

import { Link } from "react-router-dom";
import { pagelast, pagefirst } from "../utils/string";
export const getYear = (date) => {
  if(date=='0000-00-00'){
    return "";
  }
  var new_date = new Date(date);
  return new_date.getYear().toString().substr(-2);
};
export const getDay = (date) => {
  if(date=='0000-00-00'){
    return "";
  }
  var new_date = new Date(date);
  return new_date.getDate();
};
export const getMonth = (date) => {
  if(date=='0000-00-00'){
    return "";
  }
  var new_date = new Date(date);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return month[new_date.getMonth()];
};
export const tag = (tags) => {
  return tags.replaceAll(",", "");
};
export const cleanAndSubstring = (data, substring) => {
  return data.replace(/<[^>]+>/g, "").substring(0, substring);
};
export const taxPagination = (
  total,
  page,
  perpage,
  query,
  category_query,
  main
) => {
  const list = [];
  for (var i = 0; i < total / perpage; i++) {
    var url = "/tax/page/" + parseInt(i + 1);
    if (query) {
      url = url + "?s=" + query;
    }
    if (category_query) {
      url = url + "?cat=" + category_query;
    }
    list.push(
      <li>
        <Link
          key={parseInt(i)}
          to={url}
          className={parseInt(page) === parseInt(i) + 1 ? "active" : ""}
        >
          {parseInt(i + 1)}
        </Link>
      </li>
    );
  }
  var first = "/tax/page/1";
  var last = "/tax/page/" + parseInt(i);
  if (query) {
    first = first + "?s=" + query;
    last = last + "?s=" + query;
  }
  if (category_query) {
    first = first + "?cat=" + category_query;
    last = last + "?cat=" + category_query;
  }
  return (
    <div className="pagination" style={{ justifyContent: "center" }}>
      <ul className="flat-pagination  float-left clearfix">
        <li className="next">
          <Link to={first}>
            <i class="fa fa-angle-double-left" /> {main.pagefirst}
          </Link>
        </li>
        {list}
        <li className="next">
          <Link to={last}>
            {main.pagelast} <i className="fa fa-angle-double-right" />
          </Link>
        </li>
      </ul>
    </div>
  );
};
