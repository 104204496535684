import React from "react";
import { FaWindows } from "react-icons/fa";
import bg_contact from "../assets/images/service-home.webp";
import {
  title_our_service,
  desc_our_service,
  sub_our_service,
  find_out_more,
} from "../utils/string";

const OurServices = ({ main }) => {
  return (
    <div className="About pb-5">
      <div className="container-fluid">
        <div className="row">
          <article className="blog-post col">
            <div
              className="row justify-content-end"
              style={{ paddingBottom: "0px" }}
            >
              <div className="padi">
                <div style={{ maxWidth: "80%" }} className="col-lg-6">
                  <div className="title-section style3 left">
                    <h6 className="title">{main.title_our_service}</h6>
                  </div>
                  <h2>{main.sub_our_service}</h2>
                  <p>
                    {main.desc_our_service}
                    {/* <span dangerouslySetInnerHTML={ { __html: d.DESC_CAREER?.replace(/<[^>]+>/g, '') } }></span>... */}
                    {/* <div dangerouslySetInnerHTML={ { __html: dasdsadsadsa} }></div> */}
                  </p>
                  <h6 className="mt-3">
                    <a href={"/service"} title="{find_out_more[window.lang]}">
                      {main.find_out_more}
                    </a>
                  </h6>
                </div>
              </div>
              <div className="col-sm-8 col-lg-6 pb-5 pl-0 pr-0">
                <div className="blog-post-image-wrapper">
                  <a href={"/person"} title="Read More">
                    <img src={bg_contact} alt="" width="100%" className="img" />
                  </a>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
