import React, { Component } from "react";
import { PageHeader, ServicePageBody, ProjectHome } from "../components";
import axios from "axios";
import {
  API_DETAIL_CAREER,
  API_MAIN,
  API_DETAIL_CAREER_BYID,
} from "../utils/api";
import { careers as car, sub_careers } from "../utils/string";
import bg_our_service from "../assets/images/pageHeader/img-career-header.jpg";

export class Detail_career extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: [],
      detail2: [],
      slug: "",
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
  }

  componentDidUpdate(prevProps) {
    // this.getMain();
    // if (this.props.match.params.slug !== prevProps.match.params.slug) {
    //   this.getData(this.props.match.params.slug);
    // }
  }

  getData(slug) {
    const headers = {};
    axios
      .get(API_DETAIL_CAREER + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const detail = res.data.careers;
        this.setState({ detail });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/home";
              // console.log("0");
            } else {
              axios
                .get(API_DETAIL_CAREER_BYID + "?id=" + detail.LANG_REF, {
                  headers: headers,
                })
                .then((res) => {
                  const detail2 = res.data.careers;
                  this.setState({ detail2 });
                  window.location.href = "/career/" + detail2.SLUG;
                });
              // console.log(detail.LANG_REF);
            }
          } else {
            console.log("LANG sama");
          }
        } else {
          console.log("LANG UNDIFINED");
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(this.props.match.params.slug);
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { detail, main } = this.state;
    const param = {
      title: car[window.lang],
      subtitle: sub_careers[window.lang],
      breadcrumb: car[window.lang],
      back: "career",
      bg_image: bg_our_service,
      home: main.home,
    };
    console.log(detail);
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section
            className="flat-row page-careers"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div
                className="careers-open pl-5 pr-5"
                style={{ backgroundColor: "#fff" }}
              >
                <div
                  className="info-apply  pl-5 pr-5 pt-3 pb-3"
                  key={detail.ID}
                >
                  <h1>{detail.POSITION}</h1>
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="margin-right_30">
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: detail.DESC_CAREER,
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="btn-apply margin-top11 text-right">
                        <a href="/uploadcv" className="flat-button style2">
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Detail_career;
