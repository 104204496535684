import React from "react";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import {
  API_NEWS_LANDING,
  API_NEWS_CATEGORY,
  API_MAIN,
  API_SUBSCRIBE,
} from "../utils/api";
import { getYear, getDay, getMonth } from "../utils/util";
import { Link } from "react-router-dom";
import {
  articles,
  categories,
  recent_post,
  enter_your_email,
} from "../utils/string";

export class RecentPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main: [],
      category: [],
      news: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getNews();
    this.getMain();
    this.getCategory();
  }

  getCategory() {
    const headers = {};
    axios
      .get(API_NEWS_CATEGORY, { headers: headers })
      .then((res) => {
        const category = res.data.news;
        this.setState({ category });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        this.setState({ news });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    var param = {
      email: data.get("email"),
      name: data.get("name"),
      message: data.get("message"),
    };

    const headers = {};
    axios
      .post(API_SUBSCRIBE, param, { headers: headers })
      .then((res) => {
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        // var data = JSON.parse(res)
        console.log(res);
        if (res.data.error) {
          const message = (
            <div className="contact-form-error alert alert-danger mt-4">
              <strong>Error!</strong> {res.data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-success alert alert-success mt-4">
              <strong>Success!</strong> {res.data.msg}
            </div>
          );
          this.setState({ message });
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { news, main, total, message, category } = this.state;
    return (
      <div className="col-lg-3">
        <div className="post-wrap post-list">
          <article className="entry clearfix pl-3">
            <div className="entry-border clearfix">
              <div className="message">{message}</div>
              <form
                onSubmit={this.handleSubmit}
                id="subscribe-form"
                className="flat-mailchimp"
                method="post"
                action="/#"
                data-mailchimp="true"
              >
                <div className="field clearfix" id="subscribe-content">
                  <p className="wrap-input-email">
                    <input
                      type="text"
                      tabIndex={2}
                      id="subscribe-email"
                      name="email"
                      placeholder={main.enter_your_email}
                      required
                    />
                  </p>
                  <p className="wrap-btn">
                    <h2>
                      <button
                        type="submit"
                        id="subscribe-button"
                        className="flat-button subscribe-submit"
                        style={{ background: "#0c4491" }}
                        title="Subscribe now"
                      >
                        {main.subscribe}
                      </button>
                    </h2>
                  </p>
                </div>
                <div id="subscribe-msg" />
              </form>
            </div>
            <div className="entry-border clearfix pt-3">
              <h2>{main.recent_post}</h2>
            </div>
            <hr className="solid tall" />
            {news &&
              news.map((d) => (
                <div className="entry-border clearfix">
                  <p>
                    {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                    {getYear(d.DATE_NEWS)} | Admin
                  </p>
                  <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                    <h6>{d.TITLE}</h6>
                  </a>
                  {/* <Link to={"/tax/" + d.SLUG + "/#detailTax"}>
                    <h5>{d.TITLE}</h5>
                  </Link> */}
                </div>
              ))}
            <hr className="solid tall" />
            <h2>{main.categories}</h2>
            {category &&
              category.map((d) => (
                <Link to={"/tax/page/1?cat=" + d.ID} key={d.ID}>
                  <li>{d.NAME}</li>
                </Link>
              ))}
          </article>
        </div>
      </div>
    );
  }
}

export default RecentPost;
