import React from "react";
import axios from "axios";
import { API_PERSON_EXPERT, API_MAIN } from "../utils/api";
import { our_manager } from "../utils/string";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

export class Expert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expert: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const misi = res.data.main.MISI;
        const visi = res.data.main.VISI;
        const value = res.data.main.VALUE;
        const about = res.data.main.ABOUT_US;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ misi });
        this.setState({ visi });
        this.setState({ value });
        this.setState({ about });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
    this.getPersonExpert();
  }

  getPersonExpert() {
    const headers = {};
    axios
      .get(API_PERSON_EXPERT, { headers: headers })
      .then((res) => {
        const expert = res.data.person;
        this.setState({ expert });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { expert, main } = this.state;
    return (
      <section className="section-secondary custom-section-padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>{main.our_manager}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                className="row team-list sort-destination"
                data-sort-id="team"
              >
                {expert &&
                  expert.map((d) => (
                    // <div key={d.ID} className="col-sm-3">
                    //     <div className="flat-team">
                    //     <div className="avatar">
                    //         <img src={d.FULL_PATH_IMAGE} alt="imagea" />
                    //     </div>

                    //     <div className="content text-center">
                    //         <Link to={"/person/"+d.SLUG}
                    //             ><h5 className="name">{d.NAME}</h5>
                    //         </Link>
                    //         <p className="position">{d.POSITION}</p>
                    //     </div>
                    //     <h5 className="name">{d.NAME}</h5>

                    //     </div>
                    // </div>
                    <article className="blog-post col-lg-4" key={d.ID}>
                      <div className="row">
                        <div className="col-sm-8 col-lg-6">
                          <div className="blog-post-image-wrapper">
                            <img
                              src={d.FULL_PATH_IMAGE}
                              alt="asdas"
                              className="img-fluid mb-4"
                              style={{
                                padding: "10px10px",
                                backgroundColor: "#fff",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                          <p style={{ fontSize: "12px" }}>{d.POSITION}</p>
                          <h6 className="title-item-person">{d.NAME}</h6>
                          <Link to={"/person/" + d.SLUG + "#detailPerson"}>
                            <h6>
                              <a>{main.detail}</a>
                            </h6>
                          </Link>
                        </div>
                      </div>
                    </article>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Expert;
