import React, { Component } from "react";
import { PageHeader, ProjectHome, TaxPageBody } from "../components";
import axios from "axios";
import { API_NEWS, API_MAIN, API_NEWS_CATEGORY } from "../utils/api";
import { insight, sub_insight } from "../utils/string";
import bg_news from "../assets/images/pageHeader/img-newsupdate-header.jpg";

export class Tax_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      main: [],
      category: [],
      total: "",
      perpage: "",
      query: new URLSearchParams(this.props.location.search).get("s"),
      category_query: new URLSearchParams(this.props.location.search).get(
        "cat"
      ),
    };
  }

  componentDidMount() {
    this.getNews(this.props.match.params.page);
    this.getMain();
    this.getCategory();
  }

  componentDidUpdate(prevProps) {
    var s = new URLSearchParams(this.props.location.search).get("s");
    var c = new URLSearchParams(this.props.location.search).get("cat");
    if (
      this.props.match.params.page !== prevProps.match.params.page ||
      this.state.query !== s ||
      this.state.category_query !== c
    ) {
      const query = s;
      const category_query = c;
      this.setState({ query, category_query });
      this.getNews(this.props.match.params.page);
    }
  }

  getCategory() {
    const headers = {};
    axios
      .get(API_NEWS_CATEGORY, { headers: headers })
      .then((res) => {
        const category = res.data.news;
        this.setState({ category });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getNews(page) {
    var s = new URLSearchParams(this.props.location.search).get("s");
    var c = new URLSearchParams(this.props.location.search).get("cat");
    var url = API_NEWS + "?pagin=" + page;
    if (s) {
      url = url + "&search=" + s;
    }
    if (c) {
      url = url + "&category=" + c;
    }
    const headers = {};
    axios
      .get(url, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        const total = res.data.total;
        const perpage = res.data.limit_per_page;
        this.setState({ news, total, perpage });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { news, main, total, perpage, query, category, category_query } =
      this.state;
    const param = {
      title: main.news_and_update,
      subtitle: sub_insight[window.lang],
      breadcrumb: main.news_and_update,
      back: "tax/page/1",
      bg_image: bg_news,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <TaxPageBody
            main={main}
            param={news}
            page={this.props.match.params.page}
            total={total}
            perpage={perpage}
            query={query}
            category={category}
            category_query={category_query}
          />
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Tax_page;
