const SearchBar = ({ searchQuery, setSearchQuery }) => (
    <form action="/tax/page/1" id="searchform-all" method="get">
        <div>
        <input 
                value={searchQuery}
                onInput={e => setSearchQuery(e.target.value)}
                type="text" 
                placeholder="Search" 
                style={{border: '1px solid', textIndent: '10px'}}
                name="s"
                id="input-search"
            />
        <input type="submit" id="searchsubmit" />
        </div>
    </form>
);

export default SearchBar