import React, { Component } from "react";
import { PageHeader, BlogPageBody, ProjectHome } from "../components";
import axios from "axios";
import { API_SERVICES, API_MAIN } from "../utils/api";
import { title_our_service } from "../utils/string";
import bg_our_service from "../assets/images/pageHeader/img-services-header.jpg";

export class Service_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      main: [],
    };

    this.getMain();
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_SERVICES, { headers: headers })
      .then((res) => {
        const services = res.data.services;
        this.setState({ services });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { services, main } = this.state;
    const param = {
      title: title_our_service[window.lang],
      subtitle: "",
      breadcrumb: "Services",
      back: "service",
      bg_image: bg_our_service,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <BlogPageBody param={services} main={main} />
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Service_page;
