import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// CSS
import "./assets/stylesheets/bootstrap.css";
import "./assets/stylesheets/style.css";
import "./assets/stylesheets/responsive.css";
import "./assets/stylesheets/colors/color1.css";
import "./assets/stylesheets/animate.css";
import "./assets/stylesheets/headline.css";
import "./assets/revolution/css/layers.css";
import "./assets/revolution/css/settings.css";
import "./assets/stylesheets/custom.css";
import "./assets/stylesheets/owl.carousel.css";
import "./assets/stylesheets/owl.carousel/assets/owl.carousel.min.css";
import "./assets/stylesheets/owl.carousel/assets/owl.theme.default.min.css";

ReactDOM.render(
  <React.StrictMode>
    {/* <div class="loader-container">
      <div class="loader"></div>
    </div> */}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
