import React, { Component } from "react";
import {
  Banner,
  Banner2,
  OurServices,
  About,
  Teamwork,
  Testimoni,
  Quote,
  Client,
  Leader,
  ProjectHome,
  NewsHome,
} from "../components";
import {
  API_BANNERS,
  API_CLIENT_LANDING,
  API_SERVICES,
  API_MAIN,
  API_FAQ_LANDING,
  API_STEP_LANDING,
  API_PERSON_LEADER,
} from "../utils/api";
import axios from "axios";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      services: [],
      cases: [],
      clients: [],
      main: [],
      faq: [],
      step: [],
      leader: [],
    };
  }
  componentDidMount() {
    this.getBanner();
    this.getServices();
    this.getClient();
    this.getMain();
    this.getFaq();
    this.getStep();
  }

  getStep() {
    const headers = {};
    axios
      .get(API_STEP_LANDING, { headers: headers })
      .then((res) => {
        const step = res.data.strategies;
        this.setState({ step });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getFaq() {
    const headers = {};
    axios
      .get(API_FAQ_LANDING, { headers: headers })
      .then((res) => {
        const faq = res.data.faqs;
        this.setState({ faq });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getClient() {
    const headers = {};
    axios
      .get(API_CLIENT_LANDING, { headers: headers })
      .then((res) => {
        const clients = res.data.clients;
        this.setState({ clients });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getBanner() {
    const headers = {};
    axios
      .get(API_BANNERS, { headers: headers })
      .then((res) => {
        const banners = res.data.sliders;
        this.setState({ banners });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getServices() {
    const headers = {};
    axios
      .get(API_SERVICES, { headers: headers })
      .then((res) => {
        const services = res.data.services;
        this.setState({ services });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getPersonLeader() {
    const headers = {};
    axios
      .get(API_PERSON_LEADER, { headers: headers })
      .then((res) => {
        const leader = res.data.person;
        this.setState({ leader });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { banners, main, leader } = this.state;
    return (
      <main>
        <div role="main" className="main">
          <Banner2 data={banners} />
          {/* <Industry /> */}
          <div style={{ marginTop: "100px" }}>
            <OurServices main={main} />
          </div>
          <About main={main} />
          <Teamwork main={main} />
          <ProjectHome />
          <NewsHome main={main} />
          {/* <Testimoni /> */}
          {/* <Leader data={leader} />
                    <Quote />                     */}
          {/* <Client /> */}
        </div>
      </main>
    );
  }
}
