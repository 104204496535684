import React from "react";
import { Link } from "react-router-dom";
import { what_we_do, desc_service, list_of_service } from "../utils/string";

const BlogPageBody = ({ param, main }) => {
  return (
    <div class="container" style={{ width: "1200px", maxWidth: "100%" }}>
      <div class="row pt-1 pb-5 mb-3 mt-5">
        <div className="ServicesPageBody">
          {/* {param && param.map((d) => ( */}
          <div>
            <div className="row mt-2 mb-2">
              <article className="blog-post col">
                <div className="row">
                  {/* <div className="col-sm-8 col-lg-5">
                                <div className="blog-post-image-wrapper">
                                    <a href={"/service/"+d.SLUG} title="Read More">
                                        <img src={d.FULL_PATH_IMAGE} alt="" className="img-fluid mb-4" />
                                    </a>
                                </div>
                            </div> */}
                  <div>
                    <h2>{main.what_we_do}</h2>
                    <p>{main.desc_our_service2}</p>
                    {/* {<div dangerouslySetInnerHTML={ { __html:  } }></div>}                                 */}
                    {/* <hr className="solid" />                                 */}
                    {/* <a className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase" href={"/service/"+d.SLUG} title="Read More">Read More</a> */}
                  </div>
                </div>
              </article>
            </div>
            <hr className="solid tall mt-5" />
          </div>
          {/* ))} */}
          <div>
            <div className="row mt-2 mb-2">
              <article className="blog-post col">
                <div className="row">
                  {/* <div className="col-sm-8 col-lg-5">
                                <div className="blog-post-image-wrapper">
                                    <a href={"/service/"+d.SLUG} title="Read More">
                                        <img src={d.FULL_PATH_IMAGE} alt="" className="img-fluid mb-4" />
                                    </a>
                                </div>
                            </div> */}
                  <div>
                    <h2>{main.list_of_service}</h2>
                    {param &&
                      param.map((d) => (
                        <Link to={"/service/" + d.SLUG}>
                          <p className="p">{d.TITLE}</p>
                        </Link>

                        // {<div dangerouslySetInnerHTML={ { __html: d.TITLE } }></div>}
                      ))}

                    {/* <hr className="solid" />                                 */}
                    {/* <a className="btn btn-outline custom-border-width btn-primary custom-border-radius font-weight-semibold text-uppercase" href={"/service/"+d.SLUG} title="Read More">Read More</a> */}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPageBody;
