import React, { Component } from "react";
import { PageHeader, DetailEventsBody, ProjectHome } from "../components";
import axios from "axios";
import {
  API_DETAIL_NEWS,
  API_DETAIL_EVENTS,
  API_MAIN,
  API_NEWS_CATEGORY,
  API_POST_KEHADIRAN,
  API_NEWS_LANDING,
  API_DETAIL_EVENTS_BYID,
} from "../utils/api";
import { insight, sub_insight } from "../utils/string";
import bg_news from "../assets/images/pageHeader/img-newsupdate-header.jpg";
import bg_image from "../assets/images/background-invitation.jpg";

export class Detail_events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      detail: [],
      detail2: [],
      peserta: [],
      slug: "",
      category: [],
      main: [],
      showHideUndangan: false,
      undangan: [],
      kolega: [],
      materi: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
  }

  componentDidMount() {
    this.getMain();
    this.getNews(this.props.match.params.page);
    this.getCategory();
  }

  componentDidUpdate(prevProps) {
    var s = new URLSearchParams(this.props.location.search).get("s");
    var c = new URLSearchParams(this.props.location.search).get("cat");
    if (
      this.props.match.params.page !== prevProps.match.params.page ||
      this.state.query !== s ||
      this.state.category_query !== c
    ) {
      const query = s;
      const category_query = c;
      this.setState({ query, category_query });
      this.getNews(this.props.match.params.page);
      // this.getData(this.props.match.params.slug);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.getData(
        this.props.match.params.slug,
        this.props.match.params.idpeserta
      );
    }
  }

  getCategory() {
    const headers = {};
    axios
      .get(API_NEWS_CATEGORY, { headers: headers })
      .then((res) => {
        const category = res.data.events;
        this.setState({ category });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const events = res.data.events;
        this.setState({ events });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getData(slug, idpeserta) {
    const headers = {};
    axios
      .get(API_DETAIL_EVENTS_BYID + "?id=" + slug + "&idpeserta=" + idpeserta, {
        headers: headers,
      })
      .then((res) => {
        const detail = res.data.events;
        const peserta = res.data.peserta_events;
        this.setState({ detail });
        this.setState({ peserta });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/home";
              // console.log("0");
            } else {
              axios
                .get(
                  API_DETAIL_EVENTS_BYID +
                    "?id=" +
                    detail.LANG_REF +
                    "&idpeserta=" +
                    idpeserta,
                  {
                    headers: headers,
                  }
                )
                .then((res) => {
                  const detail2 = res.data.events;
                  // const peserta = res.data.peserta_events;
                  // console.log(peserta);
                  this.setState({ detail2 });
                  // this.setState({ peserta });
                  window.location.href =
                    "/events/" +
                    detail2.ID +
                    "/" +
                    idpeserta +
                    "/" +
                    this.props.match.params.kolega;
                });
              // console.log(detail.LANG_REF);
            }
          } else {
            console.log("LANG sama");
          }
        } else {
          console.log("LANG UNDIFINED");
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(
          this.props.match.params.slug,
          this.props.match.params.idpeserta
        );
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(event);
    const data = new FormData(event.target);
    // console.log(data);

    var param = {
      slug: this.props.match.params.slug,
      idpeserta: this.props.match.params.idpeserta,
      status: data.get("hadir"),
      name_c: data.get("name"),
      company_c: data.get("company"),
      email_c: data.get("email"),
      materi: data.get("materi"),
    };

    const headers = {};
    axios
      .post(API_POST_KEHADIRAN, param, { headers: headers })
      .then((res) => {
        console.log(res);
        // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
        if (res.error) {
          const message = (
            <div className="contact-form-error alert alert-danger ">
              <strong>Error!</strong> {res.data.msg}
              <span className="mail-error-message text-1 d-block" />
            </div>
          );
          this.setState({ message });
        } else {
          const message = (
            <div className="contact-form-primary alert alert-primary">
              <strong>Success!</strong> {res.data.msg}
            </div>
          );
          this.setState({ message });
          setInterval(function () {
            window.location.href = "/";
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const main = this.state.main;
    const detail = this.state.detail;
    const peserta = this.state.peserta;
    if (value == 2) {
      const kolega = [];
      const materi = [];
      if (this.props.match.params.kolega == 1) {
        kolega.push(
          <div>
            <p className="pb-2 pt-2">
              <div className="contact-form-error alert-success mb-2">
                {main.one_colega}
                {/* <span className="mail-error-message text-1 d-block" /> */}
              </div>
            </p>
            {/* <div className="message">{message}</div> */}
            <span className="flat-input pt-1">
              <input
                name="name"
                type="text"
                placeholder={main.name}
                // required="required"
              />
            </span>
            <span className="flat-input">
              <input
                type="text"
                name="company"
                placeholder={main.company}
                // required="required"
                defaultValue={""}
              />
            </span>
            <span className="flat-input">
              <input
                name="email"
                type="email"
                placeholder={main.email}
                // required="required"
              />
            </span>
            <div className="contact-form-error alert-danger mb-4">
              {main.not_colega}
              {/* <span className="mail-error-message text-1 d-block" /> */}
            </div>
          </div>
        );
      }
      this.setState({ kolega });
      this.hideComponent(false, detail, peserta, main, kolega, materi);
    } else {
      const kolega = [];
      const materi = [];
      materi.push(
        <div>
          <p className="pb-2 pt-2">
            <div className="contact-form-error alert-success mb-2">
              {main.for_not_attend}
              {/* <span className="mail-error-message text-1 d-block" /> */}
            </div>
            {main.accept_materi}
          </p>
          <input
            type="radio"
            name="materi"
            value="1"
            // checked={this.state.materi === 1}
            // onChange={this.onMateriChanged}
          />
          &nbsp;{main.yes}&nbsp;&nbsp;
          <input
            type="radio"
            name="materi"
            value="0"
            // checked={this.state.site === result.SITE_NAME}
            // onChange={this.onSiteChanged}
          />
          &nbsp;{main.no}
        </div>
      );
      this.setState({ materi });
      this.hideComponent(false, detail, peserta, main, kolega, materi);
    }
  };

  hideComponent(kondisi, detail, peserta, main, kolega, materi) {
    const headers = {};

    if (kondisi) {
      const undangan = [];
      const showHideUndangan = false;
      this.setState({ undangan });
      this.setState({ showHideUndangan });
    } else {
      const undangan = [];
      undangan.push(
        <div>
          <div className="content-item pr-5 pl-5 pt-3">
            <h2 className="title-item pt-2">{detail.NAME}</h2>

            <div
              dangerouslySetInnerHTML={{
                __html: detail.DESC_EVENT,
              }}
            ></div>
          </div>
          <hr className="solid tall" />
          <div className="pr-5 pl-5 pt-3 pb-2">
            <div>
              <h3 className="title-item pt-2">{main.rsvp}</h3>
            </div>
            <div>
              <p>{main.name}</p>

              <h4>{peserta.NAMA}</h4>
            </div>
            <div>
              <p>{main.company}</p>

              <h4>{peserta.COMPANY}</h4>
            </div>
            <div>
              <p>{main.email}</p>

              <h4>{peserta.EMAIL}</h4>
            </div>
          </div>
          <hr className="solid tall" />
          <div className="pr-5 pl-5 pb-5">
            <form
              onSubmit={this.handleSubmit}
              className="contactform style4  clearfix pr-5 pl-2"
            >
              <h3 className="pb-2">{main.attend}</h3>
              <input
                type="radio"
                name="hadir"
                value="2"
                // checked={this.state.site === result.SITE_NAME}
                onChange={this.handleChange}
              />
              &nbsp;{main.will_attend}
              <p></p>
              {kolega}
              <input
                type="radio"
                name="hadir"
                value="1"
                // checked={this.state.site === result.SITE_NAME}
                onChange={this.handleChange}
              />
              &nbsp;{main.not_attend}
              <p></p>
              {materi}
              <div class="col-md-3 pt-5">
                <div class="form-group">
                  <span className="flat-input">
                    <button
                      name="submit"
                      type="submit"
                      className="flat-button-cv"
                      id="submit"
                      title="Submit now"
                    >
                      {main.send_invite}
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
      const showHideUndangan = true;
      this.setState({ undangan });
      this.setState({ showHideUndangan });
    }
  }

  render() {
    const {
      events,
      detail,
      main,
      category,
      peserta,
      undangan,
      kolega,
      materi,
      showHideUndangan,
      message,
    } = this.state;
    const param = {
      title: main.events,
      subtitle: sub_insight[window.lang],
      breadcrumb: main.events,
      back: "#",
      breadcrumbLink: "#",
      bg_image: bg_news,
      home: main.home,
    };
    // const undangan = [];
    // if (param.FEATURE_DOC) {
    //   // download.push(<a href={"http://localhost/pro-visioner/admin/docs/index/"+param.FEATURE_DOC} target="_blank">DOWNLOAD</a>)
    //   undangan.push(

    //   );
    // }
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          {/* <DetailEventsBody
            param={detail}
            peserta={peserta}
            category={category}
            title={param}
            events={events}
            main={main}
          /> */}
          <section className="flat-row services-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-wrap">
                    <div
                      className="item item-details"
                      style={{
                        background: `url(${bg_image})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="events">
                        <div
                          className="content-item pr-5 pl-5 pt-3"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <h5 className="">{main.undagan}</h5>
                          {/* <br> */}
                          <h5 className=" pt-4 pb-4">Provisio Consulting</h5>
                          <h5 className="">{main.invite}</h5>
                          <p>
                            <strong className=" pt-4">{peserta.NAMA}</strong>
                          </p>
                          <p>
                            <strong>{peserta.COMPANY}</strong>
                          </p>
                          <p className=" pb-4">
                            <strong>{main.in_place}</strong>
                          </p>

                          <button
                            onClick={() =>
                              this.hideComponent(
                                showHideUndangan,
                                detail,
                                peserta,
                                main
                              )
                            }
                          >
                            {main.open_invite}
                          </button>
                        </div>
                        {undangan}
                        <div className="message">{message}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <RecentPost />
          <div className="col-lg-9 pt-3">
            <h2>{main.other_news}</h2>
            <hr className="solid tall" />
            {news &&
              news.map((d) => (
                <div className="entry-border clearfix pl-5">
                  <p>
                    {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                    {getYear(d.DATE_NEWS)} | Admin
                  </p>
                  <Link to={"/tax/" + d.SLUG}>
                    <h6>{d.TITLE}</h6>
                  </Link>
                </div>
              ))}
          </div> */}
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Detail_events;
