import React from "react";

const PageHeader = ({ param }) => {
  return (
    <div style={{ backgroundColor: "#f0f0f0" }}>
      <div className="container pt-3 pb-3">
        <div className="row">
          <div className="col-md-12">
            <div className="breadcrumbs pull-right">
              <ul>
                <li className="home">
                  <a href="/">{param.home}</a>
                </li>
                <li>
                  <a href={"/" + param.back}>{param.breadcrumb}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="page-title"
        style={{
          background: `url(${param.bg_image}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <h1 className="title ">{param.title}</h1>
          </div>
        </div>
        {/* <img src={bg_our_team} width="100%"/> */}
      </div>
    </div>
  );
};

export default PageHeader;
