import React, { Component } from "react";
import { PageHeader, ProjectHome } from "../components";
import axios from "axios";
import { API_CAREER, API_MAIN } from "../utils/api";
import { Link } from "react-router-dom";
import {
  be_provisioner,
  title_job,
  grade,
  be_provisioner_desc,
} from "../utils/string";
import { careers as car, sub_careers } from "../utils/string";
import img_service_1 from "../assets/images/services/c1.jpeg";
import img_service_2 from "../assets/images/services/c2.jpeg";
import img_service_3 from "../assets/images/services/c3.jpeg";
import bg_carrer from "../assets/images/pageHeader/img-career-header.jpg";

export class Career_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      careers: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_CAREER, { headers: headers })
      .then((res) => {
        const careers = res.data.careers;
        this.setState({ careers });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
    this.getMain();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { careers, main } = this.state;
    const param = {
      title: main.career,
      subtitle: sub_careers[window.lang],
      breadcrumb: main.career,
      back: "career",
      bg_image: bg_carrer,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section
            className="flat-row page-careers"
            style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div className="careers-info">
                <div className="row">
                  {/* <div className="col-lg-6">
                                <div className="careers-featured clearfix">
                                <div className="img"><img src={img_service_1} alt="imageas" /></div>
                                <div className="img"><img src={img_service_2} alt="imagea" /></div>
                                <div className="img"><img src={img_service_3} alt="imagea" /></div>
                                </div>
                            </div> */}
                  {/* <div className="col-lg-6">  */}
                  <div className="careers-text pl-3">
                    <h1>{main.be_provisioner}</h1>
                    <p>{main.be_provisioner_desc}</p>
                    {/* <a href="/contact" className="flat-button">contact us</a> */}
                    <hr className="solid tall" />
                    <table class="table">
                      <thead>
                        <tr>
                          <th>
                            <h3>{main.title_job}</h3>
                          </th>
                          <th>
                            <h3>{main.grade}</h3>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {careers &&
                          careers.map((d) => (
                            <tr>
                              <td>{d.POSITION}</td>
                              <td>{d.GRADE}</td>
                              <td>
                                <Link
                                  to={"/career/" + d.SLUG}
                                  className="flat-button style2"
                                >
                                  {main.detail}
                                </Link>
                              </td>
                            </tr>
                            // {<div dangerouslySetInnerHTML={ { __html: d.TITLE } }></div>}
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* </div> */}
              </div>

              <div className="careers-open">
                {/* <div className="title-section text-center">
                            <h1 className="title">Current Open</h1>
                            <div className="sub-title">
                                We are here to Acelerate your business and help you find the way.
                            </div>
                            </div> */}
                {/* {careers && careers.map((d) => (
                            <div className="info-apply" key={d.ID}>
                                <h3>{d.POSITION}</h3>
                                <div className="row">
                                    <div className="col-lg-9">
                                    <div className="margin-right_30">
                                        <p><span dangerouslySetInnerHTML={ { __html: d.DESC_CAREER } }></span></p>
                                    </div>
                                    </div>
                                    <div className="col-lg-3">
                                    <div className="btn-apply margin-top11 text-right">
                                        <a href="/#" className="flat-button style2">Apply Now</a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            ))} */}
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Career_page;
