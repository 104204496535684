import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import React, { Component } from "react";
import { Footer, Header, RouteChangeTracker } from "./components";
import {
  Home,
  Service_page,
  Detail_service,
  About_page,
  Visi,
  Misi,
  Person,
  Value,
  Career_page,
  Detail_career,
  Upload_cv,
  Case_page,
  Tax_page,
  Contact_page,
  Detail_case,
  Detail_tax,
  Detail_person,
  Legal_page,
  VisiMisi,
  Detail_events,
  Kuisioner,
  Thank,
} from "./pages";
import axios from "axios";
import { API_SERVICES, API_MAIN } from "./utils/api";
import ReactGa from "react-ga4";

// ReactGa.initialize("UA-206783698-1");
ReactGa.initialize("G-5G8XJ25K12");

export default class App extends Component {
  constructor(props) {
    super(props);
    window.lang = "en";

    this.state = {
      services: [],
      cases: [],
      main: [],
      pages: [],
      // loading: true,
    };
  }

  componentDidMount() {
    // this.fakeRequest().then(() => {
    //   const el = document.querySelector(".loader-container");
    //   if (el) {
    //     el.remove(); // removing the spinner element
    //     this.setState({ loading: false }); // showing the app
    //   }
    // });
    this.getMain();
    this.getServices();
  }

  // fakeRequest = () => {
  //   return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  // };

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        window.lang = res.data.main.LANG;
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getServices() {
    const headers = {};
    axios
      .get(API_SERVICES, { headers: headers })
      .then((res) => {
        const services = res.data.services;
        this.setState({ services });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    // if (this.state.loading) {
    //   return null; //app is not ready (fake request is in process)
    // }
    const { main, services } = this.state;

    return (
      <BrowserRouter>
        <RouteChangeTracker />
        <Header services={services} main={main} />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/service" component={Service_page} exact />
          <Route
            path="/service/:slug"
            component={Detail_service}
            exact={true}
          />
          <Route path="/about" component={About_page} exact />
          <Route path="/visi" component={Visi} exact />
          <Route path="/misi" component={Misi} exact />
          <Route path="/visimisi" component={VisiMisi} exact />
          <Route path="/value" component={Value} exact />
          <Route path="/person" component={Person} exact />
          <Route path="/person/:slug" component={Detail_person} exact />
          <Route path="/career" component={Career_page} exact />
          <Route path="/career/:slug" component={Detail_career} exact />
          <Route path="/uploadcv" component={Upload_cv} exact />
          <Route path="/case" component={Case_page} exact />
          <Route path="/case/:slug" component={Detail_case} exact />
          <Route path="/tax" component={Tax_page} exact />
          <Route path="/tax/:slug" component={Detail_tax} exact />
          <Route
            path="/events/:slug/:idpeserta/:kolega"
            component={Detail_events}
            exact
          />
          <Route path="/monev/:no/:slug" component={Kuisioner} exact />
          <Route path="/thank" component={Thank} exact />
          <Route path="/tax/page/:page" component={Tax_page} exact />
          <Route path="/legal" component={Legal_page} exact />
          {/* <Route  path="/tax/:slug" component={Detail_tax} exact/> */}
          <Route path="/legal/page/:page" component={Legal_page} exact />
          <Route path="/contact" component={Contact_page} exact />
        </Switch>
        <Footer main={main} />
      </BrowserRouter>
    );
  }
}
