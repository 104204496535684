import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Banner2 = ({data}) => {
    return (
        <div>
        <section style={{height: '560px'}}>
            <OwlCarousel
                        items={1}
                        animateOut= 'fadeOut'
                        margin={8}
                        loop
                        nav
                        height = '560px'
                        autoplay ={true}
                        autoplayTimeout= '9500'  >
                {data &&
                        data.map((d) => (
                    <div
                    key={d.ID} className="owl-d pt-2"
                    style={{ background: `url('${d.FULL_PATH_IMAGE}') no-repeat center center`, backgroundSize:'cover' , height:'560px'}}
                    >
                        <center>
                            <div className="inner" >
                                <h5>{d.HEADER_TITLE}</h5>
                                <div className="title-section style3 left">
                                <h1 className="title">{d.TITLE}</h1>
                                </div>
                                <h3 style={{fontWeight:'2'}}>{d.SUB_TITLE}</h3>
                                <div style={{marginTop:'30px'}}>
                                </div>
                            </div>
                        </center>
                    </div>
                ))}
            </OwlCarousel>
        </section>
        </div>

    )
}

export default Banner2;