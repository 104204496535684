import React, { Component } from "react";
import { PageHeader, DetailTaxBody, ProjectHome } from "../components";
import axios from "axios";
import {
  API_DETAIL_NEWS,
  API_MAIN,
  API_NEWS_CATEGORY,
  API_NEWS,
  API_NEWS_LANDING,
  API_DETAIL_NEWS_BYID,
} from "../utils/api";
import { insight, sub_insight } from "../utils/string";
import bg_news from "../assets/images/pageHeader/img-newsupdate-header.jpg";

export class Detail_tax extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      detail: [],
      detail2: [],
      slug: "",
      category: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
    this.getNews(this.props.match.params.page);
    this.getCategory();
  }

  componentDidUpdate(prevProps) {
    var s = new URLSearchParams(this.props.location.search).get("s");
    var c = new URLSearchParams(this.props.location.search).get("cat");
    if (
      this.props.match.params.page !== prevProps.match.params.page ||
      this.state.query !== s ||
      this.state.category_query !== c
    ) {
      const query = s;
      const category_query = c;
      this.setState({ query, category_query });
      this.getNews(this.props.match.params.page);
      // this.getData(this.props.match.params.slug);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.getData(this.props.match.params.slug);
    }
  }

  getCategory() {
    const headers = {};
    axios
      .get(API_NEWS_CATEGORY, { headers: headers })
      .then((res) => {
        const category = res.data.news;
        this.setState({ category });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        this.setState({ news });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getData(slug) {
    const headers = {};
    axios
      .get(API_DETAIL_NEWS + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const detail = res.data.news;
        this.setState({ detail });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/home";
              // console.log("0");
            } else {
              axios
                .get(API_DETAIL_NEWS_BYID + "?id=" + detail.LANG_REF, {
                  headers: headers,
                })
                .then((res) => {
                  const detail2 = res.data.news;
                  this.setState({ detail2 });
                  window.location.href = "/tax/" + detail2.SLUG;
                });
              // console.log(detail.LANG_REF);
            }
          } else {
            console.log("LANG sama");
          }
        } else {
          console.log("LANG UNDIFINED");
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(this.props.match.params.slug);
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { news, detail, main, category } = this.state;
    const param = {
      title: main.news_and_update,
      subtitle: sub_insight[window.lang],
      breadcrumb: main.news_and_update,
      back: "tax/page/1",
      breadcrumbLink: "/tax/page/1",
      bg_image: bg_news,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <div role="main" class="main" id="detailTax"></div>
          <DetailTaxBody
            param={detail}
            category={category}
            title={param}
            news={news}
            main={main}
          />
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Detail_tax;
