import React from "react";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import { API_CASES, API_MAIN } from "../utils/api";
import {
  are_your_looking_for_a,
  professional_tax_consultan,
  contact_us_right_now,
  mail_us,
} from "../utils/string";
import { Link } from "react-router-dom";

export class ProjectHome extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      { width: 850, itemsToShow: 5 },
      { width: 1150, itemsToShow: 5, itemsToScroll: 2 },
      { width: 1450, itemsToShow: 5 },
      { width: 1750, itemsToShow: 6 },
    ];
    this.state = {
      cases: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
    this.getCases();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getCases() {
    const headers = {};
    axios
      .get(API_CASES, { headers: headers })
      .then((res) => {
        const cases = res.data.projects;
        this.setState({ cases });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { cases, main } = this.state;
    var menu = "";
    if (main.LANG == "en") {
      menu = "style3";
    } else {
      menu = "style4";
    }
    return (
      <div className="top">
        {/* <section className="flat-row v4 bg-section"> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6 reponsive-onehalf">
              <div className={"title-section " + menu + " left"}>
                <h2 className="title">{main.are_your_looking_for_a}</h2>
              </div>
              <h2 style={{ display: "none" }}>
                {main.professional_tax_consultan}
              </h2>
              <h5 className="title m-0">{main.contact_us_right_now}</h5>
            </div>

            <div className="col-md-6 reponsive-onehalf">
              <div className="wrap-widget-header clearfix">
                <aside className="widget widget-info">
                  <div className="textwidget clearfix">
                    <ul className="flat-top social-links">
                      <li>
                        <div
                          className="info-icon"
                          style={{ background: "#ef7701", color: "#0c4491" }}
                        >
                          <i className="fa fa-phone" />
                        </div>
                        <div className="info-text">
                          <a
                            href={"tel:" + main.NO_TELP}
                            className="text-decoration-none"
                            target="_blank"
                            rel="noreferrer"
                            title="Call Us"
                          >
                            <h3 style={{ marginBottom: "5px" }}>
                              {main.NO_TELP}
                            </h3>
                          </a>
                        </div>
                        <div
                          className="info-icon"
                          style={{ background: "#ef7701", color: "#0c4491" }}
                        >
                          <i className="fa fa-phone" />
                        </div>
                        <div className="info-text">
                          <a
                            href={"tel:" + main.NO_TELP2}
                            className="text-decoration-none"
                            target="_blank"
                            rel="noreferrer"
                            title="Call Us"
                          >
                            <h3 style={{ marginBottom: "5px" }}>
                              {main.NO_TELP2}
                            </h3>
                          </a>
                        </div>
                      </li>
                      <li className="links">
                        <div className="info-text">
                          <h5 style={{ marginBottom: "20px" }}>{main.OR}</h5>
                        </div>
                      </li>
                      <li>
                        <div className="info-text" style={{ marginBottom: "20px" }}>
                          {/* <Link
                            to="/contact"
                            className="flat-button"
                            style={{ top: "5px" }}
                          >
                            {main.mail_us}
                          </Link> */}
                          <a
                            href="/contact"
                            className="flat-button"
                            style={{ top: "5px" }}
                          >
                            {" "}
                            {main.mail_us}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid" style={{paddingLeft:'0',paddingRight:'0'}}>
                    <div className="row">
                        <div className="col">
                            <div className="row team-list sort-destination" data-sort-id="team">
                            <Carousel
                                ref={ref => (this.carousel = ref)}
                                showArrows={false}
                                itemsToShow={5}
                                breakPoints={this.breakPoints}
                                onResize={currentBreakPoint => console.log(currentBreakPoint)}>

                                {cases &&
                                cases.map((d) => (
                                    <div className="imagebox" key={d.ID}>
                                            <div className="imagebox-image">
                                            <Link to={"/case/"+d.SLUG}><img src={d.FULL_PATH_IMAGE} alt="gambar" /></Link>
                                            </div>
                                            <div className="imagebox-content">
                                            <h5 className="imagebox-title"><Link to={"/case/"+d.SLUG}>{d.NAME}</Link></h5>
                                            <p className="imagebox-category">{d.TAG}</p>
                                            </div>
                                    </div>
                                ))}
                            </Carousel>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* </section> */}
      </div>
    );
  }
}

export default ProjectHome;
