import React, { Component } from "react";
import axios from "axios";
import {
  PageHeader,
  Leader,
  Expert,
  ProjectHome,
  MenuAbout,
} from "../components";
import { our_expert } from "../utils/string";
import {
  API_DETAIL_PERSON,
  API_OTHER_PERSON,
  API_MAIN,
  API_DETAIL_PERSON_BYID,
} from "../utils/api";
import bg_our_team from "../assets/images/pageHeader/img-ourteam-header.jpg";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

export class Detail_person extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: [],
      detail2: [],
      other: [],
      slug: "",
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
    this.getOther(this.props.match.params.slug);
  }

  componentDidUpdate(prevProps) {
    // this.getMain();
    // if (this.props.match.params.slug !== prevProps.match.params.slug) {
    //   this.getData(this.props.match.params.slug);
    // }
  }

  getOther(slug) {
    const headers = {};
    axios
      .get(API_OTHER_PERSON + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const other = res.data.person;
        this.setState({ other });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getData(slug) {
    const headers = {};
    axios
      .get(API_DETAIL_PERSON + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const detail = res.data.person;
        this.setState({ detail });
        if (this.state.main.LANG) {
          if (detail.LANG != this.state.main.LANG) {
            if (detail.LANG_REF == 0) {
              window.location.href = "/home";
              // console.log("0");
            } else {
              axios
                .get(API_DETAIL_PERSON_BYID + "?id=" + detail.LANG_REF, {
                  headers: headers,
                })
                .then((res) => {
                  const detail2 = res.data.person;
                  this.setState({ detail2 });
                  window.location.href = "/person/" + detail2.SLUG;
                });
              // console.log(detail.LANG_REF);
            }
          } else {
            console.log("LANG sama");
          }
        } else {
          console.log("LANG UNDIFINED");
        }
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
        this.getData(this.props.match.params.slug);
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { detail, main, other } = this.state;
    const header = {
      title: main.our_team,
      subtitle: "Meet our professionals",
      breadcrumb: "People",
      back: "person",
      bg_image: bg_our_team,
      home: main.home,
    };
    return (
      <main>
        <div role="main" className="main" id="main">
          <PageHeader param={header} />
          {/* <div className="page-title parallax parallax1">
                        <div className="section-overlay" />
                        <div className="container">
                            <div className="row">
                            <div className="col-md-12">
                                <div className="page-title">
                                <h1 className="title">Leader</h1>
                                </div>
                                <div style={{color:'#fff'}}>
                                    We are The best people to help you.
                                </div>
                            </div>
                            </div>
                        </div>
                    </div> */}
          <section className="flat-row services-details" id="detailPerson">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="sidebar">
                    <div className="widget widget-help">
                      <div className="featured-widget">
                        <img
                          src={detail.FULL_PATH_IMAGE}
                          style={{
                            padding: "20px 20px",
                            backgroundColor: "#fff",
                          }}
                          alt="images"
                        />
                      </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="item-wrap">
                    <div
                      className="item item-details"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div className="content-item">
                        <h5 style={{ color: "#363232" }}>
                          - {detail.POSITION} -
                        </h5>
                        <h2
                          className="title-item-person pb-2"
                          style={{ fontSize: "40px", lineHeight: "normal" }}
                        >
                          {detail.NAME}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detail.DESC_PERSON,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ProjectHome />
          <section className="section-secondary custom-section-padding">
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-3">
                  <div className="title-section style3 left">
                    <h2 className="title" style={{ paddingRight: "100px" }}>
                      {our_expert[window.lang]}
                    </h2>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div
                    className="row team-list sort-destination"
                    data-sort-id="team"
                  >
                    {other &&
                      other.map((d) => (
                        <article
                          key={d.ID}
                          className="entry border-shadow clearfix"
                          style={{ padding: "10px" }}
                        >
                          <div className="entry-border clearfix">
                            <div
                              className="content-posts"
                              style={{
                                background: `url(${d.FULL_PATH_IMAGE}) no-repeat center center`,
                                backgroundSize: "cover",
                                width: "250px",
                                height: "450px",
                              }}
                            >
                              <div
                                className="MenuLink"
                                style={{ paddingTop: "300px" }}
                              >
                                <div className="other">
                                  <h4>
                                    <span className="team-member-desc font-weight-light">
                                      {d.POSITION}
                                    </span>
                                  </h4>
                                  <h3>
                                    <span className="team-member-name text-color-dark font-weight-semibold text-4">
                                      {d.NAME}
                                    </span>
                                  </h3>
                                  <h6>
                                    <a
                                      href={
                                        "/person/" + d.SLUG + "#detailPerson"
                                      }
                                    >
                                      DETAILS +
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                        // <div key={d.ID} className="col-md-3 isotope-item leadership" >
                        //     <div className="team-item mb-3 p-0 pb-2">
                        //         <a href={"/person/"+d.SLUG} className="text-decoration-none">
                        //         <span className="image-wrapper">
                        //             <img src={d.FULL_PATH_IMAGE} width="100%" alt="" className="img-fluid" />
                        //         </span>
                        //         </a>
                        //         <div className="team-infos" style={{height:'150px'}}>
                        //             {/* <div className="share">
                        //                 <i className="fas fa-share-alt" />
                        //                 <div className="share-icons bg-color-light">
                        //                 <a href="/#" className="text-decoration-none" title="Share on Facebook"><i className="fab fa-facebook-f" /></a>
                        //                 <a href="/#" className="text-decoration-none" title="Share on Instagram"><i className="fab fa-instagram" /></a>
                        //                 <a href="/#" className="text-decoration-none" title="Share on Linkedin"><i className="fab fa-linkedin-in" /></a>
                        //                 </div>
                        //             </div> */}
                        //             <a href={"/person/"+d.SLUG} className="text-decoration-none">
                        //                 <span className="team-member-name text-color-dark font-weight-semibold text-4">{d.NAME}</span>
                        //                 <span className="team-member-desc font-weight-light">{d.POSITION}</span>
                        //             </a>
                        //         </div>
                        //     </div>
                        // </div>
                      ))}
                  </div>
                </div>
              </div>
              <hr className="solid tall mt-5" />
            </div>
          </section>

          <MenuAbout />
        </div>
      </main>
    );
  }
}

export default Detail_person;
