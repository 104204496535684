import React, { Component } from "react";
import { PageHeader, PageBody, ProjectHome, MenuAbout } from "../components";
import { API_MAIN } from "../utils/api";
import { visionmision, vision, mision } from "../utils/string";
import axios from "axios";
import bg_visi from "../assets/images/pageHeader/img-visionmision-header.jpg";

export class VisiMisi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      main: [],
    };
  }

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const content = res.data.main;
        const main = res.data.main;
        this.setState({ main });
        this.setState({ content });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { content, main } = this.state;
    const header = {
      title: main.VISI_MISI,
      breadcrumb: main.VISI_MISI,
      back: main.VISI_MISI,
      misi: main.mision,
      visi: main.vision,
      bg_image: bg_visi,
      home: main.home,
    };
    return (
      <main>
        <div
          role="main"
          className="main"
          style={{ paddingBottom: "30px" }}
          id="VisiMisi"
        >
          <PageHeader param={header} />
          <div className="container pl-3">
            <div
              className="row"
              style={{ marginTop: "30px", marginBottom: "80px" }}
            >
              <div className="col">
                <h2>{header.visi}</h2>
                <p align="justify">
                  <PageBody param={content.VISI} title={header} />
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{ marginTop: "30px", marginBottom: "80px" }}
            >
              <div className="col">
                <h2>{header.misi}</h2>
                <p align="justify">
                  <PageBody param={content.MISI} title={header} />
                </p>
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "30px" }}></div>
          <ProjectHome />
          <hr className="solid tall mt-5" />
          <MenuAbout />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default VisiMisi;
