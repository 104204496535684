import React from "react"
import { taxPagination } from "../utils/util"
import {
    Link
  } from "react-router-dom";

const BlogPageBody = ({param, page, total, perpage, query, category, category_query}) => {
    var pencarian = ''
    if(query){
        pencarian= <h2>Search Results for: <strong>"{query}"</strong></h2>
    }
    return (
        <section className="flat-row blog-list">
            <div className="container">
            <div className="row">
                <div className="col">
                {pencarian}
                <div className="post-wrap post-list">
                    {param && param.map((d) => (
                        <article className="entry border-shadow clearfix">
                        <div className="entry-border clearfix">
                            <div className="featured-post">
                            <Link to={"/tax/"+d.SLUG}><img src={d.FULL_PATH_IMAGE} alt="imagex" style={{maxHeight:'320px'}} /></Link>
                            </div>
                            <div className="content-post">
                            <span className="category">{d.TAG}</span>
                            <h2 className="title-post"><Link to={"/tax/"+d.SLUG}>{d.TITLE}</Link></h2>
                            <p><span dangerouslySetInnerHTML={ { __html: d.DESC_NEWS.replace(/<[^>]+>/g, '').substring(0,80) } }></span>...</p>
                            <Link to={"/tax/"+d.SLUG} className="readmore">READ MORE</Link>
                            </div>
                        </div>
                        </article>
                    ))}
                </div>  
                <div className="blog-pagination clearfix">
                     {taxPagination(total,page,perpage,query,category_query)}
                </div>{/* /.blog-pagination */} 
                </div>
            </div>{/* /.row */}           
            </div>{/* /.container */}   
        </section>
    )
}

export default BlogPageBody;