import React from "react";
import { taxPagination } from "../utils/util";
import { getYear, getDay, getMonth } from "../utils/util";
import { categories, read_more, recent_post } from "../utils/string";
import { SearchBar, RecentPost } from "../components";
import { Link } from "react-router-dom";

const BlogPageBody = ({
  main,
  param,
  page,
  total,
  perpage,
  query,
  category,
  category_query,
}) => {
  var pencarian = "";
  if (query) {
    pencarian = (
      <h2>
        Search Results for: <strong>"{query}"</strong>
      </h2>
    );
  }

  return (
    <section className="flat-row blog-list">
      <div className="container">
        {pencarian}

        <div className="row">
          {/* <div className="col-lg-3">
                <div className="sidebar">
                    <div className="widget widget-testimonials">

                    <h3>Categories</h3>
                    <ul>
                        {category && category.map((d) => (
                            <Link to={"/tax/page/1?cat="+d.ID} key={d.ID}><li>{d.NAME}</li></Link>
                        ))}
                    </ul>

                    </div>
                </div>
                </div> */}
          {param &&
            param.map((d) => (
              <div className="col-lg-3">
                <div className="post-wrap post-list">
                  <article className="entry border-shadow clearfix">
                    <div className="entry-border clearfix">
                      <div className="featured-post">
                        <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                          <img
                            src={d.FULL_PATH_IMAGE}
                            alt="image"
                            style={{ width: "100%" }}
                          />
                        </a>
                        {/* <Link to={"/tax/" + d.SLUG}>
                          <img
                            src={d.FULL_PATH_IMAGE}
                            alt="image"
                            style={{ width: "100%" }}
                          />
                        </Link> */}
                      </div>
                      <div className="content-post pl-4 pr-4 pt-2">
                        <span className="category">
                          {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                          {getYear(d.DATE_NEWS)}
                        </span>
                        <h5 className="title-post">
                          <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                            <h6>{d.TITLE}</h6>
                          </a>
                          {/* <Link to={"/tax/" + d.SLUG + "/#detailTax"}>
                            {d.TITLE}
                          </Link> */}
                        </h5>
                        <br></br>
                        <div className="meta-data style2 clearfix">
                          <p>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d.DESC_NEWS?.replace(
                                  /<[^>]+>/g,
                                  ""
                                ).substring(0, 150),
                              }}
                            ></span>
                            ...
                          </p>
                          <ul className="meta-post clearfix">
                            <li className="day-time">
                              <a href={"/tax/" + d.SLUG + "/#detailTax"}>
                                <span style={{ color: "#ef7701" }}>
                                  {main.read_more}
                                </span>
                              </a>
                              {/* <Link to={"/tax/" + d.SLUG + "/#detailTax"}>
                                <span style={{ color: "#ef7701" }}>
                                  {main.read_more}
                                </span>
                              </Link> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                {/* /.blog-pagination */}
              </div>
            ))}
          <RecentPost />
          {/* <div className="col-lg-3">
                    <div className="post-wrap post-list">
                        <article className="entry clearfix">
                        <div className="entry-border clearfix">
                            <form onSubmit={this.handleSubmit} id="subscribe-form" className="flat-mailchimp" method="post" action="/#" data-mailchimp="true">
                                <div className="field clearfix" id="subscribe-content">
                                    <p className="wrap-input-email">
                                    <input type="text" tabIndex={2} id="subscribe-email" name="email" placeholder="Enter Your Email" required />
                                    </p>
                                    <p className="wrap-btn">
                                    <button type="submit" id="subscribe-button" className="flat-button subscribe-submit" title="Subscribe now">SUBSCRIBE</button>
                                    </p>
                                </div>
                                <div id="subscribe-msg" />
                            </form>
                        </div>
                        <div className="entry-border clearfix pt-3">
                            <h2>{recent_post[window.lang]}</h2>
                        </div>
                        <hr className="solid tall" />
                        {param && param.map((d) => (
                        <div className="entry-border clearfix">
                            <p>{getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)} {getYear(d.DATE_NEWS)} | Admin</p>
                            <Link to={"/tax/"+d.SLUG}><h5>{d.TITLE}</h5></Link>
                        </div>
                         ))}
                         <hr className="solid tall" />
                         <h2>{categories[window.lang]}</h2>
                         {category && category.map((d) => (
                            <Link to={"/tax/page/1?cat="+d.ID} key={d.ID}><li>{d.NAME}</li></Link>
                        ))}
                        </article>
                    </div>
                </div>   */}
          {/* /.blog-pagination */}
        </div>
        {/* /.row */}
        <div className="col-lg-9">
          <div className="blog-pagination clearfix">
            {taxPagination(total, page, perpage, query, category_query, main)}
          </div>
        </div>
      </div>
      {/* /.container */}
    </section>
  );
};

export default BlogPageBody;
