import React, { Component } from "react";
import { PageHeader } from "../components";
import axios from "axios";
import { API_CASES, API_MAIN } from "../utils/api";
import { industries } from "../utils/string";

export class Case_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cases: [],
      tags: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getCases();
    this.getMain();
  }

  getCases() {
    const headers = {};
    axios
      .get(API_CASES, { headers: headers })
      .then((res) => {
        const cases = res.data.projects;
        this.setState({ cases });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { cases, main } = this.state;
    const param = {
      title: industries[window.lang],
      subtitle: "",
      breadcrumb: industries[window.lang],
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <section>
            <div className="container py-2">
              <div className="row">
                <div className="col-lg-12">
                  <p align="justify"></p>
                </div>
                <div
                  className="col-lg-12"
                  style={{
                    border: "1px solid",
                    padding: "10px 30px",
                    margin: "20px auto",
                  }}
                >
                  {cases &&
                    cases.map((d) => (
                      <ul>
                        <li>
                          <a href={"/case/" + d.SLUG}>
                            <span style={{ fontWeight: "600" }}>{d.NAME}</span>
                          </a>
                          <br />
                          <div
                            dangerouslySetInnerHTML={{ __html: d.DESC_PROJECT }}
                          ></div>
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
}

export default Case_page;
