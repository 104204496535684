import React from "react";
import bg_contact from "../assets/images/about-us-home2.webp";
import { find_out_more, about, about_desc, who_we_are } from "../utils/string";

const About = ({ main }) => {
  return (
    <div className="About pb-5">
      <article className="blog-post col">
        <div className="row" style={{ padding: "0px 0px" }}>
          <div className="col-sm-8 col-lg-6 pb-5 pl-0 pr-0">
            <div className="blog-post-image-wrapper">
              <a href={"/about"} title="Read More">
                <img src={bg_contact} alt="" width="100%" className="img" />
              </a>
            </div>
          </div>
          <div
            className="col-sm-12 col-lg-4 pr-5"
            style={{ maxWidth: "600px" }}
          >
            <div className="title-section style3 left">
              <h6 className="title"> {main.about} </h6>
            </div>
            <h2> {main.who_we_are}</h2>
            <p>
              {main.about_desc}
              {/* <span dangerouslySetInnerHTML={ { __html: d.DESC_CAREER?.replace(/<[^>]+>/g, '') } }></span>... */}
              {/* <div dangerouslySetInnerHTML={ { __html: dasdsadsadsa} }></div> */}
            </p>
            <h6 class="mt-3">
              <a href={"/about"} title="{find_out_more[window.lang]}">
                {main.find_out_more}
              </a>
            </h6>
          </div>
        </div>
      </article>
    </div>
  );
};

export default About;
