import React, { Component } from "react";
import {
  PageHeader,
  Leader,
  Expert,
  ProjectHome,
  MenuAbout,
} from "../components";
import { API_MAIN, API_PERSON_LEADER, API_PERSON_EXPERT } from "../utils/api";
import { our_team } from "../utils/string";
import axios from "axios";
import bg_our_team from "../assets/images/pageHeader/img-ourteam-header.jpg";

export class Person extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      leader: [],
      expert: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getMain();
    this.getPersonLeader();
    this.getPersonExpert();
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getPersonLeader() {
    const headers = {};
    axios
      .get(API_PERSON_LEADER, { headers: headers })
      .then((res) => {
        const leader = res.data.person;
        this.setState({ leader });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getPersonExpert() {
    const headers = {};
    axios
      .get(API_PERSON_EXPERT, { headers: headers })
      .then((res) => {
        const expert = res.data.person;
        this.setState({ expert });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { main, leader, expert } = this.state;
    const header = {
      title: main.our_team,
      subtitle: "Meet our professionals",
      breadcrumb: main.PEOPLE,
      back: main.PEOPLE,
      bg_image: bg_our_team,
      home: main.home,
    };
    return (
      <main>
        <div
          role="main"
          className="main"
          style={{ paddingBottom: "30px" }}
          id="Person"
        >
          <PageHeader param={header} />

          <div style={{ marginTop: "30px" }}>
            <Leader param={leader} />
          </div>
          <div style={{ marginTop: "30px" }}>
            <ProjectHome />
          </div>
          <div style={{ marginTop: "30px" }}>
            <Expert data={expert} />
          </div>
          <hr className="solid tall mt-5" />
          <MenuAbout />
        </div>
      </main>
    );
  }
}

export default Person;
