import React, { Component } from "react";
import { PageHeader, ProjectHome } from "../components";
import { API_MAIN, API_POST_MESSAGE } from "../utils/api";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import {
  contact,
  sub_contact,
  desc_contact,
  send_a_message,
  address,
  phone,
  email,
  contact_details,
} from "../utils/string";
import bg_contact_us from "../assets/images/pageHeader/img-contactus-header.jpg";
import ReCAPTCHA from "react-google-recaptcha";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class Contact_page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      main: [],
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  static defaultProps = {
    center: {
      lat: -6.2925042,
      lng: 106.7827908,
    },
    zoom: 18,
  };

  componentDidMount() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  onChange(value) {
    console.log("Captcha value:", value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    if (data.get("g-recaptcha-response") != "") {
      var param = {
        email: data.get("email"),
        name: data.get("name"),
        message: data.get("message"),
      };

      const headers = {};
      axios
        .post(API_POST_MESSAGE, param, { headers: headers })
        .then((res) => {
          console.log(res);
          // var res = JSON.stringify({error:true,msg:"Thank you for the message you sent, we will immediately respond to the message."});
          if (res.data.error) {
            const message = (
              <div className="contact-form-error alert alert-danger mt-4">
                <strong>Error!</strong> {res.data.msg}
                <span className="mail-error-message text-1 d-block" />
              </div>
            );
            this.setState({ message });
          } else {
            const message = (
              <div className="contact-form-success alert alert-success mt-4">
                <strong>Success!</strong> {res.data.msg}
              </div>
            );
            this.setState({ message });
          }
        })
        .catch((error) => {
          console.log("Error yaa ", error);
        });
    } else {
      const message = (
        <div className="contact-form-error alert alert-danger mt-4">
          <strong>Error!</strong> Please verify that you are a Human.
          <span className="mail-error-message text-1 d-block" />
        </div>
      );
      this.setState({ message });
    }
  }

  render() {
    const recaptchaRef = React.createRef();
    const { main, message } = this.state;
    const header = {
      title: main.contact,
      subtitle: sub_contact[window.lang],
      breadcrumb: main.contact,
      back: main.contact,
      bg_image: bg_contact_us,
      home: main.home,
    };
    const key = main.site_key;
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={header} />
          <section className="flat-row page-contact">
            <div className="container">
              <div className="wrap-formcontact style2">
                <div style={{ width: "100%" }}>
                  <h1>{main.contact_details}</h1>

                  <div className="row">
                    {/* <div className="col-lg-6">
                                <div className="flat-maps box-shadow3">
                                <div className="maps2" style={{width: '100%', height: '570px'}} />
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: "AIzaSyDsxogRU95BlQ6TWbZoH0jI4Q03XnesWvk"}}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}
                                >
                                <AnyReactComponent
                                    lat={main.LAT}
                                    lng={main.LON}
                                    text="Pro Visioner"
                                />
                                </GoogleMapReact>
                                </div>
                            </div> */}
                    <div className="col-lg-6 pt-4">
                      <div className="contact">
                        <h3>{main.phone}</h3>
                        <a
                          href={"tel:" + main.NO_TELP}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                          title="Call Us"
                        >
                          <h4>{main.NO_TELP}</h4>
                        </a>
                        <div className="pt-2"></div>
                        <a
                          href={"tel:" + main.NO_TELP2}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                          title="Call Us"
                        >
                          <h4>{main.NO_TELP2}</h4>
                        </a>
                      </div>
                      <div className="contact">
                        <h3>{main.email}</h3>
                        <a
                          href={"mailto:" + main.EMAIL}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                          title="Mail Us"
                        >
                          <h4>{main.EMAIL}</h4>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 pt-4">
                      <div className="contact">
                        <h3>{main.address}</h3>
                        <h4
                          style={{ lineHeight: "25px" }}
                          className="info-text"
                          dangerouslySetInnerHTML={{ __html: main.ADDRESS }}
                        ></h4>
                      </div>
                    </div>

                    <form
                      onSubmit={this.handleSubmit}
                      className="contactform style4  clearfix pr-5 pl-2"
                    >
                      <hr className="solid tall mt-5" />
                      <h1 className="pt-5 pb-2">{main.send_message}</h1>
                      <p>{main.desc_message}</p>
                      <div className="message">{message}</div>
                      <span className="flat-input">
                        <input
                          name="name"
                          type="text"
                          placeholder={main.name}
                          required="required"
                        />
                      </span>
                      <span className="flat-input">
                        <input
                          name="email"
                          type="email"
                          placeholder={main.email}
                          required="required"
                        />
                      </span>
                      <span className="flat-input">
                        <textarea
                          name="message"
                          placeholder={main.message}
                          required="required"
                          defaultValue={""}
                        />
                      </span>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfTBB4eAAAAADHTfINYaagZmNarD4GzMbsAtUtn"
                        // sitekey="6LcgCB4eAAAAAHNfU8JWGyLyTgwhDkjxrq-V8Zj_"
                        onChange={this.onChange}
                      />
                      {/* {button} */}
                      <div class="col-md-3">
                        <div class="form-group">
                          <span className="flat-input">
                            <button
                              name="submit"
                              type="submit"
                              className="flat-button-cv"
                              id="submit"
                              title="Submit now"
                            >
                              {main.send}
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ProjectHome />
          <div className="blank"></div>
        </div>
      </main>
    );
  }
}

export default Contact_page;
