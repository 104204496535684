import React, { Component } from "react";
import { PageHeader, CasePageBody } from "../components";
import axios from "axios";
import { API_DETAIL_CASE, API_MAIN } from "../utils/api";

export class Detail_case extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: [],
      slug: "",
      main: [],
    };
  }

  componentDidMount() {
    this.getData(this.props.match.params.slug);
    this.getMain();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.getData(this.props.match.params.slug);
    }
  }

  getData(slug) {
    const headers = {};
    axios
      .get(API_DETAIL_CASE + "?slug=" + slug, { headers: headers })
      .then((res) => {
        const detail = res.data.projects;
        detail.DATE_NEWS = detail.DATE_PROJECT;
        detail.DESC_NEWS = detail.DESC_PROJECT;
        this.setState({ detail });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { detail, main } = this.state;
    const param = {
      title: detail.NAME,
      subtitle: "",
      breadcrumb1: detail.NAME,
      breadcrumb: "Industries",
      breadcrumbLink: "/case",
    };
    return (
      <main>
        <div role="main" className="main">
          <PageHeader param={param} />
          <CasePageBody param={detail} title={param} />
        </div>
      </main>
    );
  }
}

export default Detail_case;
