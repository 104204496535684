import React from "react";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import { API_NEWS_LANDING, API_MAIN } from "../utils/api";
import { getYear, getDay, getMonth } from "../utils/util";
import { Link } from "react-router-dom";
import { articles, read_more, news_and_update } from "../utils/string";

export class NewsHome extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
      { width: 400, itemsToShow: 3, itemsToScroll: 3, pagination: false },
    ];
    this.state = {
      news: [],
      main: [],
    };
  }

  componentDidMount() {
    this.getNews();
    this.getMain();
  }
  getMain() {
    const headers = {};
    axios
      .get(API_MAIN, { headers: headers })
      .then((res) => {
        const main = res.data.main;
        this.setState({ main });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }
  getNews() {
    const headers = {};
    axios
      .get(API_NEWS_LANDING, { headers: headers })
      .then((res) => {
        const news = res.data.news;
        this.setState({ news });
      })
      .catch((error) => {
        console.log("Error yaa ", error);
      });
  }

  render() {
    const { news, main } = this.state;
    return (
      <section className="flat-row v6 pt-4">
        <div className="container pl-2">
          <div className="row pl-2">
            <div className="col-md-12">
              <div className="title-section style3 left">
                <h6 className="title">{main.articles}</h6>
              </div>
              <div className="title-section mb-2">
                <h1 className="title">{main.news_and_update}</h1>
              </div>
            </div>
          </div>
          <div className="">
            <div className="blog-shortcode post-grid">
              <Carousel
                ref={(ref) => (this.carousel = ref)}
                showArrows={false}
                itemsToShow={3}
                breakPoints={this.breakPoints}
                onResize={(currentBreakPoint) => console.log(currentBreakPoint)}
              >
                {news &&
                  news.map((d, i) => (
                    <Link to={"/tax/" + d.SLUG}>
                      <article
                        key={d.ID}
                        className={`entry border-shadow clearfix newshome-${i}`}
                        style={{ background: "#fff" }}
                      >
                        <div
                          className="entry-border clearfix"
                          style={{ height: "600px" }}
                        >
                          <div className="featured-post">
                            <Link to={"/tax/" + d.SLUG}>
                              <img
                                src={d.FULL_PATH_IMAGE}
                                alt="images"
                                style={{
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxHeight: "250px",
                                }}
                              />
                            </Link>
                          </div>
                          <div className="content-post">
                            <span className="category">
                              {getDay(d.DATE_NEWS)} {getMonth(d.DATE_NEWS)}{" "}
                              {getYear(d.DATE_NEWS)}
                            </span>
                            <h2 className="title-post">
                              <Link to={"/tax/" + d.SLUG}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: d.TITLE?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    ).substring(0, 30),
                                  }}
                                ></span>
                                ...
                              </Link>
                            </h2>
                            <div className="meta-data style2 clearfix">
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: d.DESC_NEWS?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    ).substring(0, 150),
                                  }}
                                ></span>
                                ...
                              </p>
                              <ul className="meta-post clearfix">
                                <li className="day-time">
                                  <span>
                                    <a>{main.read_more}</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </article>
                    </Link>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NewsHome;
