import React from "react"
import { getYear, getDay, getMonth } from "../utils/util"

const CasePageBody = ({param, title}) => {

    return (
        <section className="flat-row services-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="widget widget-help">
                  <div className="featured-widget">
                    <img src={param.FULL_PATH_IMAGE} alt="images" />
                  </div>
                  <p>
                    Posted By: Admin pro-visioner 
                    <br/>
                    Date: {getDay(param.DATE_PROJECT)} {getMonth(param.DATE_PROJECT)} {getYear(param.DATE_PROJECT)}
                    <br/>
                    Tags: {param.TAG}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="item-wrap">
                <div className="item item-details">
                  <div className="content-item">
                    <h2 className="title-item">{title.title}</h2>
                    <div dangerouslySetInnerHTML={ { __html: param.DESC_PROJECT } }></div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default CasePageBody;